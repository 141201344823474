import React, { useEffect, useRef, useState } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { Row, Col, Container } from "react-bootstrap";
import banner from "../images/fluentzea-banner.webp";
import whylearn from "../images/why-learn-a-foreign-language.webp";
import whylearnfrench from "../images/why-learn-french.webp";
import blogData from "../components/blogData.json";
import BlogCarousel from "../components/blogcarousel";
import Accordionmain from "../components/accordion";
import arrowWhite from "../images/arrow-white.svg";
import formimg from "../images/form-img.png";
import axios from "axios";
import { Link } from "react-router-dom";
import fluentzeatabimg from "../images/francetab.webp";
import studytripsimg from "../images/fluentzea-study-trips.webp";
import internshipsimg from "../images/fluentzea-internships.webp";
import studyprogramsimg from "../images/fluentzea-study-programs.webp";
import StudentCarousel from "../components/studentcarousel";
import studentimg from "../images/studentimg.svg";
// import video from "../files/testimonials.mp4";
// import thumbnail from "../images/Thumbnail.png";



function Fluentzea() {
  const servicetabobj = {
    internships: {
      image: fluentzeatabimg,
      title: "Internships",
      text:
        "ALZEA INDIA offers comprehensive internship experiences in Francophone countries, primarily France and Mauritius, spanning three to twelve months. Gain hands-on experience in various domains within the hospitality industry, from culinary arts, food and beverage to housekeeping.",
    },
    studyprograms: {
      image: fluentzeatabimg,
      title: "Study Programs",
      text:
        "As a hub of everything Francophone, ALZEA INDIA provides study programs in Francophone countries like Canada and France. Study programs require one to be fluent in the language of their course chosen as well as the language of the host country, which isn’t a worry at ALZEA INDIA, thanks to our inclusive Fluentzea portal and services.",
    },
    studytrips: {
      image: fluentzeatabimg,
      title: "Study Trips",
      text:
        "ALZEA INDIA believes that learning a language is incomplete without experiencing the culture or essence of the country from which it originates. Thus, our initiative of study trips comes to life, where we take you to places where the languages are most spoken. We also facilitate cultural exchange by bringing people from France to be introduced to our culture.",
    },
  };
  const [activeTab, setActiveTab] = useState("internships");

  const handleClick = (tab) => {
    setActiveTab(tab);
  };

  const [BlogsForCarousel, setBlogsForCarousel] = useState([]);

  useEffect(() => {
    if (blogData) {
      const importImages = async () => {
        const images = await Promise.all(
          blogData.map(async (data) => {
            const imageModule = await import(`../images/${data.image}`);
            return imageModule.default;
          })
        );
        setBlogsForCarousel(
          blogData.map((data, index) => ({
            id: data.id,
            title: data.title,
            image: images[index],
            date: data.date,
            type: data.type,
            style: data.styles,
          }))
        );
      };
      importImages();
    }
  }, []);

  const faqItems = [
    {
      title: "What courses does Fluentzea offer?",
      value:
        "Fluentzea provides a variety of French language courses suitable for different needs and aspirations. Whether you're learning for leisure, academic purposes, career enhancement, or planning to move to a French-speaking country, we have courses tailored for you. Our offerings include DELF to TEF, covering all proficiency levels.",
    },
    {
      title:
        "Who can enroll in Fluentzea courses?",
      value:
        "Our courses are open to everyone, including school and college students looking to enhance their language skills. Students participating in study trips, study programs, and internships with us gain access to our basic French online course as part of their program benefits.",
    },
  ];

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    college: "",
    query: "",
  });

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .post("http://localhost:3001/fluentzeaformfillup", formData) // Pass formData directly
      .then((res) => {
        console.log("registered successfully");
        setFormData({
          name: "",
          email: "",
          mobileNumber: "",
          college: "",
          query: "",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const studentData = [
    {
      image: studentimg,
      studentname: "RATHI Sanat",
      score: "GRADE A",
      course: "DELF A1",
      content:
        "Sanat Rathi, at such a young age, with the help of our one-on-one online classes, was able to ace his DELF A1 exams and score a grade A. Sanat initially sought assistance with the French syllabus at his school, but after experiencing our classes, his interest in French grew exponentially. Consequently, he decided to pursue full-time one-on-one online classes with us and appeared for the globally recognized DELF examination at such a young age. After the concerted efforts of both the teacher and the student, we are proud to say he cleared with flying colors.",
    },
    {
      image: studentimg,
      studentname: "RATHI Sanat",
      score: "GRADE A",
      course: "DELF A1",
      content:
        "Sanat Rathi, at such a young age, with the help of our one-on-one online classes, was able to ace his DELF A1 exams and score a grade A. Sanat initially sought assistance with the French syllabus at his school, but after experiencing our classes, his interest in French grew exponentially. Consequently, he decided to pursue full-time one-on-one online classes with us and appeared for the globally recognized DELF examination at such a young age. After the concerted efforts of both the teacher and the student, we are proud to say he cleared with flying colors.",
    },
    {
      image: studentimg,
      studentname: "RATHI Sanat",
      score: "GRADE A",
      course: "DELF A1",
      content:
        "Sanat Rathi, at such a young age, with the help of our one-on-one online classes, was able to ace his DELF A1 exams and score a grade A. Sanat initially sought assistance with the French syllabus at his school, but after experiencing our classes, his interest in French grew exponentially. Consequently, he decided to pursue full-time one-on-one online classes with us and appeared for the globally recognized DELF examination at such a young age. After the concerted efforts of both the teacher and the student, we are proud to say he cleared with flying colors.",
    },
    {
      image: studentimg,
      studentname: "RATHI Sanat",
      score: "GRADE A",
      course: "DELF A1",
      content:
        "Sanat Rathi, at such a young age, with the help of our one-on-one online classes, was able to ace his DELF A1 exams and score a grade A. Sanat initially sought assistance with the French syllabus at his school, but after experiencing our classes, his interest in French grew exponentially. Consequently, he decided to pursue full-time one-on-one online classes with us and appeared for the globally recognized DELF examination at such a young age. After the concerted efforts of both the teacher and the student, we are proud to say he cleared with flying colors.",
    },
  ];

  // const [controlsVisible, setControlsVisible] = useState(false);
  // const videoRef = useRef(null);
  // const handleVideoClick = () => {
  //   setControlsVisible(true);
  //   const videoElement = videoRef.current;
  //   // Reset currentTime to 0 if video has ended
  //   if (videoElement.ended) {
  //     videoElement.currentTime = 0;
  //   }
  //   // Play the video
  //   if (videoElement) {
  //     videoElement.play().catch((error) => {
  //       console.error("Error playing video:", error);
  //     });
  //   }
  // };

  const targetDivRef = useRef(null);

  // Function to handle scrolling to the target div
  const scrollToDiv = () => {
    const targetDivPosition = targetDivRef.current.getBoundingClientRect().top;
    window.scrollTo({
      top: targetDivPosition - 100,
      behavior: 'smooth'
    });
  };

  return (
    <div className="sec-start">
      <Header />
      <Container>
        <Row>
          <Col md="6">
            <h1>Fluentzea</h1>
            <p>
            Crafted meticulously by Team ALZEA INDIA, Fluentzea is a comprehensive series of French language training courses tailored to prepare our students for their academic sojourn in France, Mauritius or any Francophone destination. Recognizing the paramount importance of linguistic competence, we aim to equip our students with the indispensable language skills requisite for effective communication during their tenure in France.
            </p>
            <p>Our meticulously designed courses serve as a conduit for enhancing linguistic proficiency, laying a robust foundation for seamless interaction within the French-speaking ambience. Beyond mere language acquisition, Fluentzea endeavours to facilitate a nuanced understanding and adaptation to the rich tapestry of French culture and environment.Our mission is to empower students with the linguistic dexterity needed to navigate academic, social, and professional landscapes in France with confidence and finesse.</p>
            <button type="Submit" onClick={scrollToDiv}>Apply Now</button>
          </Col>
          <Col md="6" className="mt-md-0 mt-5">
            <img
              src={banner}
              style={{ width: "100%" }}
              alt="Fluentzea Banner"
            />
          </Col>
        </Row>
        <Row className="pt-5 ">
          <Col md="6">
            <img
              src={whylearn}
              style={{ width: "100%" }}
              alt="Why Learn A Foreign Language"
            />
          </Col>
          <Col md="6" style={{ margin: "auto 0" }} className="mt-md-0 mt-5">
            <h2>Why Learn A Foreign Language?</h2>
            <p className="why-go-through-us-p">
              <span className="number">1</span>
              <span>
              <b>Global Professional Opportunities and Resume Enhancement</b> : In today's globalized world, fluency in a second language opens doors to international career prospects, making you a valuable asset in various industries and diplomatic settings.
              </span>
            </p>
            <p className="why-go-through-us-p">
              <span className="number">2</span>
              <span>
              <b>Enhanced Memory and Attention</b> : Learning a foreign language stimulates memory and attention, providing cognitive benefits that extend beyond linguistic proficiency.
              </span>
            </p>
            <p className="why-go-through-us-p">
              <span className="number">3</span>
              <span>
              <b>Cultural Exposure and Networking</b> : Language is the gateway to understanding different cultures, fostering empathy and global interconnectedness.
              </span>
            </p>
          </Col>
        </Row>
        <Row className="pt-5 pb-5">
          <Col md="6" style={{ margin: "auto 0" }}>
            <h2>Why Learn French?</h2>
            <p className="why-go-through-us-p">
              <span className="number">1</span>
              <span>
              <b>Francophone Community</b> - French, apart from being the language of love, is also spoken by over 300 million people worldwide. Joining the global Francophone community connects you with individuals sharing a rich cultural heritage across diverse regions. This community extends beyond France to various countries in Africa, Canada, and other regions.
              </span>
            </p>
            <p className="why-go-through-us-p">
              <span className="number">2</span>
              <span>
              <b>Educational and Work Opportunities</b> - France boasts some of the world's top-tier educational institutions and is a leader in luxury and hospitality. Proficiency in French opens doors to academic scholarships, exchange programs, and lucrative career prospects.
              </span>
            </p>
            <p className="why-go-through-us-p">
              <span className="number">3</span>
              <span>
              <b>Travel and Cultural Exposure</b> - French proficiency facilitates travel to numerous countries, enriching your cultural experiences and broadening your global network. French is the only language, besides English, spoken on all continents.
              </span>
            </p>
          </Col>
          <Col md="6">
            <img
              src={whylearnfrench}
              style={{ width: "100%" }}
              alt="Why Learn A Foreign Language"
            />
          </Col>
        </Row>
        {/* <h2 className="text-center pt-5 pb-5">Why Learn French Through Us?</h2>
        <Container>
        <video
          ref={videoRef}
          controls={controlsVisible}
          className="container"
          onClick={handleVideoClick}
          onEnded={() => {
            setControlsVisible(false);
          }}
          poster={thumbnail}
        >
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        </Container> */}
        </Container>
        <div className="mt-5 mb-5 pt-5 pb-5" style={{ backgroundColor: "#f5f4f4" }}>
          <Container>
            <h2 className="text-center pb-3">What Do Our Services Include?</h2>
            <div className="servicemaintab">
              <span
                className={`servicetab ${
                  activeTab === "internships" && "active"
                }`}
                onClick={() => handleClick("internships")}
              >
                <img src={internshipsimg} alt="Internships logo" /> Internships
              </span>
              <span
                className={`servicetab ${
                  activeTab === "studyprograms" && "active"
                }`}
                onClick={() => handleClick("studyprograms")}
              >
                <img src={studyprogramsimg} alt="Study programs logo" /> Study
                programs
              </span>
              <span
                className={`servicetab ${
                  activeTab === "studytrips" && "active"
                }`}
                onClick={() => handleClick("studytrips")}
              >
                <img src={studytripsimg} alt="Study trips logo" /> Study trips
              </span>
            </div>
            <Row>
            <Col md="6 pt-5">
              <img
                src={servicetabobj[activeTab].image}
                style={{ width: "100%" }}
                alt="service"
              />
            </Col>
            <Col md="6 pt-5" style={{ margin: "auto" }}>
              <h2>{servicetabobj[activeTab].title}</h2>
              <p>{servicetabobj[activeTab].text}</p>
            </Col>
            </Row>
          </Container>
        </div>
        <Container>
        <Row className="pt-5 pb-5">
            <h2>Our Students Achievements</h2>
            <StudentCarousel data={studentData} />  
        </Row>
        <Row className="faq-study-programs">
          <Col md="12" lg="4">
            <h2>Frequently Asked Questions</h2>
            <p>
              Lorem ipsum dolor sit amet consectetur. In ut nec feugiat vitae
              vitae iaculis risus ugiat vitae vitae iaculis risus
            </p>
            <Link to="/faqs" className="view-btn">
              View All
              <img className="arrowIcon" alt="" src={arrowWhite}></img>
            </Link>
          </Col>
          <Col md="12" lg="8">
            <Accordionmain Items={faqItems} />
          </Col>
        </Row>
        <div className="mt-5 mb-5">
          <h2>Check out These Blogs</h2>
          <p style={{maxWidth:"1200px"}}>
          Read about the francophone countries, the trends in the hospitality industry, India’s relation with the french language, facts about the world abroad, the success and challenges abroad, ongoing relatable debates and much more.
          </p>
          <BlogCarousel data={BlogsForCarousel} />
        </div>
        <Row className="pt-5 pb-5"  ref={targetDivRef}>
          <Col sm="12" md="12" lg="6">
            <h2>Let’s Connect</h2>
            <img src={formimg} style={{width:"100%"}}  className="form-img mb-5" alt="Fill the form" />
          </Col>
          <Col sm="12" md="12" lg="6">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="name">
                    Name:{" "}
                    <span style={{ color: "red", fontSize: "20px" }}>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="John Doe"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="email">
                    Email:{" "}
                    <span style={{ color: "red", fontSize: "20px" }}>*</span>
                  </label>
                  <input
                    type="tel"
                    placeholder="johndoe@abc.com"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="mobileNumber">
                    Mobile Number:{" "}
                    <span style={{ color: "red", fontSize: "20px" }}>*</span>
                  </label>
                  <input
                    type="tel"
                    placeholder="829-983-1929"
                    id="mobileNumber"
                    name="mobileNumber"
                    value={formData.mobileNumber}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="college">
                    Your College:{" "}
                    <span style={{ color: "red", fontSize: "20px" }}>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="New Horizon College"
                    id="college"
                    name="college"
                    value={formData.college}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <label htmlFor="query">
                Query: <span style={{ color: "red", fontSize: "20px" }}>*</span>
              </label>
              <textarea
                rows="4"
                cols="50"
                placeholder="Leave a message"
                id="query"
                name="query"
                value={formData.query}
                onChange={handleChange}
              ></textarea>

              <button type="submit">Submit</button>
            </form>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default Fluentzea;
