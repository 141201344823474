import React, { useEffect, useState } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { Col, Container, Row } from "react-bootstrap";
import banner from "../images/france-internships-banner.webp";
import "../styles/studytrips.css";
import whyInternInFrance from "../images/why-study-in-france.webp";
import star from "../images/star.svg";
import whiteArrow from "../images/arrow-white.svg";
import tripsframe from "../images/trips-frame.webp";
import HowItsDiff from "../images/france-internship-how-its-diff.webp";
import Accordionmain from "../components/accordion";
import BlogCarousel from "../components/blogcarousel";
import blogData from "../components/blogData.json";
import formimg from "../images/form-img.png";
import { Link } from "react-router-dom";
import axios from "axios";
import whatDoes1 from "../images/what-does-it-offer-1.webp";
import whatDoes2 from "../images/what-does-it-offer-2.webp";
import tick from "../images/tick-circle.png";

const Franceinternships = () => {
  const faqItems = [
    {
      title: "Where can anyone contact ALZEA INDIA for a prompt response?",
      value:
        "For prompt response to your queries or concerns, we recommend contacting us directly using the provided email ID or phone number. Our team will be more than happy to assist you and provide you with the information you need in a timely manner.",
    },
    {
      title:
        "Is knowledge of French mandatory for internships or work permits in France or French-speaking countries like Mauritius?",
      value:
        "While not always a strict requirement, knowing French can be beneficial. We offer a complimentary in-house French language course to support interns, enhancing their language skills and competitiveness. For work permits in France, basic French knowledge is necessary, and some properties may require certification.",
    },
  ];

  const [BlogsForCarousel, setBlogsForCarousel] = useState([]);
  useEffect(() => {
    if (blogData) {
      const importImages = async () => {
        const images = await Promise.all(
          blogData.map(async (data) => {
            const imageModule = await import(`../images/${data.image}`);
            return imageModule.default;
          })
        );
        setBlogsForCarousel(
          blogData.map((data, index) => ({
            id: data.id,
            title: data.title,
            image: images[index],
            date: data.date,
            type: data.type,
            style: data.styles,
          }))
        );
      };
      importImages();
    }
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    college: "",
    leavedate: "",
    whyfrance: "",
    query: "",
  });

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .post("http://localhost:3001/francestudytripformfillup", formData) // Pass formData directly
      .then((res) => {
        console.log("registered successfully");
        setFormData({
          name: "",
          email: "",
          mobileNumber: "",
          college: "",
          leavedate: "",
          whyfrance: "",
          query: "",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="sec-start">
      <Header />
      <Container>
        <Row className="pb-5">
          <Col md="6" style={{ margin: "auto 0" }}>
            <h1>Study Trips In France</h1>
            <p>
              Lorem ipsum dolor sit amet consectetur. Dignissim eros ipsum elit
              eget morbi purus dictum ac lectus. Pelle
            </p>
            <button type="Submit">Apply Now</button>
          </Col>
          <Col md="6" className="pt-5">
            <img
              src={banner}
              style={{ width: "100%" }}
              alt="Banner France Internships"
            />
          </Col>
        </Row>
        <Row className="pt-5 pb-5">
          <Col md="6">
            <img
              src={whyInternInFrance}
              style={{ width: "100%" }}
              alt="Why Study In France"
            />
          </Col>
          <Col md="6" className="pt-md-0 pt-sm-5">
            <h2>Why Study Trips In France?</h2>
            <p className="why-go-through-us-p">
              <span className="number">1</span>
              <span>
                Lorem ipsum dolor sit amet consectetur. Consequatictumst etiam
                ultricies curabitur tincidunt cursus.
              </span>
            </p>
            <p className="why-go-through-us-p">
              <span className="number">2</span>
              <span>
                Lorem ipsum dolor sit amet consectetur. Consequatictumst etiam
                ultricies curabitur tincidunt cursus.
              </span>
            </p>
            <p className="why-go-through-us-p">
              <span className="number">3</span>
              <span>
                Lorem ipsum dolor sit amet consectetur. Consequatictumst etiam
                ultricies curabitur tincidunt cursus.
              </span>
            </p>
          </Col>
        </Row>
        <Row className="pt-5 pb-5">
          <h2 className="text-center">Who Would This Benefit?</h2>
          <div className="star-content">
            <img src={star} alt="star" />
            <span>
              Lorem ipsum dolor sit amet consectetur. Id nunc nibh eget
              adipiscing lectus quam habitasse facilisis. am habitasse
              facilisis.
            </span>
          </div>
          <div className="star-content">
            <img src={star} alt="star" />
            <span>
              Lorem ipsum dolor sit amet consectetur. Id nunc nibh eget
              adipiscing lectus quam habitasse facilisis. am habitasse
              facilisis.
            </span>
          </div>
          <div className="star-content">
            <img src={star} alt="star" />
            <span>
              Lorem ipsum dolor sit amet consectetur. Id nunc nibh eget
              adipiscing lectus quam habitasse facilisis. am habitasse
              facilisis.
            </span>
          </div>
          <div className="star-content">
            <img src={star} alt="star" />
            <span>
              Lorem ipsum dolor sit amet consectetur. Id nunc nibh eget
              adipiscing lectus quam habitasse facilisis. am habitasse
              facilisis.
            </span>
          </div>
          <button type="Submit" style={{ margin: "0 auto", maxWidth: "150px" }}>
            Know More <img src={whiteArrow} alt="Arrow" />
          </button>
        </Row>
        <Row className="pt-5 pb-5">
          <h2 className="text-center pt-3 pb-3">What Does It Offer?</h2>
          <Col md="6">
            <img
              src={whatDoes1}
              style={{ width: "100%" }}
              alt="What does it offer part 1"
            />
            <div className="whydoes-content">
              <h3>Materialistic</h3>
                <div className="whydoes-p">
                  <img src={tick} className="tick-img"  alt="tick" />
                  <span>Lorem ipsum dolor sit amet consectetur. Id nunc nibh eget
                  adipiscing lectus quam habitasse facilisis.</span>
                </div>
                <div className="whydoes-p">
                  <img src={tick} className="tick-img"  alt="tick" />
                  <span>Lorem ipsum dolor sit amet consectetur. Id nunc nibh eget
                  adipiscing lectus quam habitasse facilisis.</span>
                </div>
                <div className="whydoes-p">
                  <img src={tick} className="tick-img"  alt="tick" />
                  <span>Lorem ipsum dolor sit amet consectetur. Id nunc nibh eget
                  adipiscing lectus quam habitasse facilisis.</span>
                </div>
            </div>
          </Col>
          <Col md="6" className="pt-sm-5 pt-lg-0">
            <img
              src={whatDoes2}
              style={{ width: "100%" }}
              alt="What does it offer part 2"
            />
            <div className="whydoes-content">
              <h3>Non-Materialistic</h3>
                <div className="whydoes-p">
                  <img src={tick} className="tick-img" alt="tick" />
                  <span>Lorem ipsum dolor sit amet consectetur. Id nunc nibh eget
                  adipiscing lectus quam habitasse facilisis.</span>
                </div>
                <div className="whydoes-p">
                  <img src={tick} className="tick-img" alt="tick" />
                  <span>Lorem ipsum dolor sit amet consectetur. Id nunc nibh eget
                  adipiscing lectus quam habitasse facilisis.</span>
                </div>
                <div className="whydoes-p">
                  <img src={tick} className="tick-img" alt="tick" />
                  <span>Lorem ipsum dolor sit amet consectetur. Id nunc nibh eget
                  adipiscing lectus quam habitasse facilisis.</span>
                </div>
            </div>
          </Col>
        </Row>
        <div className="pt-5 pb-5" style={{ backgroundColor: "#f5f4f4" }}>
          <h2 className="text-center pt-3 pb-3">
            Snapshots Of Our Trips In France
          </h2>
          <img src={tripsframe} style={{ width: "100%" }} alt="Trips Frame" />
        </div>
        <Row className="pt-5 pb-5">
          <Col md="6" className="pt-md-0 pt-sm-5">
            <h2>How Is It Different?</h2>
            <p className="why-go-through-us-p">
              <span className="number">1</span>
              <span>
                Lorem ipsum dolor sit amet consectetur. Consequatictumst etiam
                ultricies curabitur tincidunt cursus.
              </span>
            </p>
            <p className="why-go-through-us-p">
              <span className="number">2</span>
              <span>
                Lorem ipsum dolor sit amet consectetur. Consequatictumst etiam
                ultricies curabitur tincidunt cursus.
              </span>
            </p>
            <p className="why-go-through-us-p">
              <span className="number">3</span>
              <span>
                Lorem ipsum dolor sit amet consectetur. Consequatictumst etiam
                ultricies curabitur tincidunt cursus.
              </span>
            </p>
          </Col>
          <Col md="6">
            <img
              src={HowItsDiff}
              style={{ width: "100%" }}
              alt="Why Study In France"
            />
          </Col>
        </Row>
        <Row className="faq-study-trips">
          <Col md="12" lg="4">
            <h2>Frequently Asked Questions</h2>
            <p>
              Lorem ipsum dolor sit amet consectetur. In ut nec feugiat vitae
              vitae iaculis risus ugiat vitae vitae iaculis risus
            </p>
            <Link to="/faqs" className="view-btn">
              View All
              <img className="arrowIcon" alt="" src={whiteArrow}></img>
            </Link>
          </Col>
          <Col md="12" lg="8">
            <Accordionmain Items={faqItems} />
          </Col>
        </Row>
        <div className="mt-5 mb-5">
          <h2>Check out These Blogs</h2>
          <p>
            Lorem ipsum dolor sit amet consectetur. In ut nec feugiat vitae
            vitae iaculis risus ugiat vitae vitae iaculis risus
          </p>
          <BlogCarousel data={BlogsForCarousel} />
        </div>
        <Row className="pt-5 pb-5">
          <Col sm="12" md="12" lg="6">
            <h2>Let’s Connect</h2>
            <img src={formimg} style={{width:"100%"}}  className="form-img" alt="Fill the form" />
          </Col>
          <Col sm="12" md="12" lg="6">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="name">
                    Name:{" "}
                    <span style={{ color: "red", fontSize: "20px" }}>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="John Doe"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="email">
                    Email:{" "}
                    <span style={{ color: "red", fontSize: "20px" }}>*</span>
                  </label>
                  <input
                    type="tel"
                    placeholder="johndoe@abc.com"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="mobileNumber">
                    Mobile Number:{" "}
                    <span style={{ color: "red", fontSize: "20px" }}>*</span>
                  </label>
                  <input
                    type="tel"
                    placeholder="829-983-1929"
                    id="mobileNumber"
                    name="mobileNumber"
                    value={formData.mobileNumber}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="college">
                    Your College:{" "}
                    <span style={{ color: "red", fontSize: "20px" }}>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="New Horizon College"
                    id="college"
                    name="college"
                    value={formData.college}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <label htmlFor="college">
                When Do You Wish To Leave?:{" "}
                <span style={{ color: "red", fontSize: "20px" }}>*</span>
              </label>
              <input
                type="date"
                placeholder="Select Date"
                id="leavedate"
                name="leavedate"
                value={formData.leavedate}
                onChange={handleChange}
              />
              <label htmlFor="query">
                Why France?:{" "}
                <span style={{ color: "red", fontSize: "20px" }}>*</span>
              </label>
              <textarea
                rows="4"
                cols="50"
                placeholder="Leave a message"
                id="whyfrance"
                name="whyfrance"
                value={formData.whyfrance}
                onChange={handleChange}
              ></textarea>

              <label htmlFor="query">
                Query: <span style={{ color: "red", fontSize: "20px" }}>*</span>
              </label>
              <textarea
                rows="4"
                cols="50"
                placeholder="Leave a message"
                id="query"
                name="query"
                value={formData.query}
                onChange={handleChange}
              ></textarea>

              <button type="submit">Submit</button>
            </form>
          </Col>
        </Row>
      </Container>

      <Footer />
    </div>
  );
};

export default Franceinternships;
