import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import banner from "../images/career-banner.webp";
import Header from "../components/header";
import Footer from "../components/footer";
import whywork from "../images/why-work-with-us.webp";
import "../styles/careers.css";
import JobCard from "../components/jobCard";
import Jobs from "../components/jobs.json";

const Careers = () => {
  // Sample job data
  const [filters, setFilters] = useState({
    type: "All",
    domain: "All",
  });

  const handleChangeFilter = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const filteredJobs = Jobs.filter((job) => {
    const typeFilter = filters.type === "All" || job.type === filters.type;
    const domainFilter =
      filters.domain === "All" || job.domain === filters.domain;
    return typeFilter && domainFilter;
  });

  return (
    <div className="sec-start">
      <Header />
      <Container>
        <Row className="pt-5 pb-5">
          <Col md="6">
            <h1>Careers Page</h1>
            <p>
            ALZEA INDIA, where passion meets purpose, and careers flourish in an environment that values innovation, collaboration, and personal growth. Join us on a journey where every day presents an opportunity to make a meaningful impact and contribute to something greater than yourself.
            </p>
          </Col>
          <Col md="6">
            <img src={banner} style={{ width: "100%" }} alt="Banner" />
          </Col>
        </Row>
        <Row style={{ backgroundColor: "#f5f4f4" }}>
          <Col md="6" className="why-work-p">
            <h2>Why Work With Us</h2>
            <ul>
            <li><b>Freedom and Flexibility:</b> At ALZEA INDIA, you have the liberty to set your own timings. We encourage
            the implementation of your ideas on a national scale, welcoming a plethora of innovative concepts to
            grow our business.</li>

            <li><b>Collaborative Environment:</b> Be part of discussions on diverse topics such as education, study abroad,
            fitness, lifestyle, and mental health. Contribute to and immerse yourself in subjects that align with our
            dynamic and fun-loving ethos.</li>

            <li><b>Passion for Mentorship:</b> We are on the lookout for individuals passionate about guiding the next
            generation. Your role will involve shaping students&#39; understanding of global hospitality standards
            through internships, contributing to the creation of a global talent pool.</li>

            <li><b>Communication and Attitude:</b> Good communication, a happy attitude, and patience are key traits we
            value. As you&#39;ll be in contact with students, these qualities will be crucial to your success in our team.</li>
            </ul>
          </Col>
          <Col md="6">
            <img
              src={whywork}
              style={{ width: "100%", height:"100%", objectFit:"cover" }}
              alt="Why work with us"
            />
          </Col>
        </Row>
        <h2 className="py-5 text-center">Ready to embark on a rewarding career journey?<br/>Explore our current opportunities and discover how you can be a part of something extraordinary.</h2>
        <div className="filters">
            <select
              name="type"
              value={filters.type}
              onChange={handleChangeFilter}
            >
              <option value="All">All</option>
              <option value="Full-time">Full-time</option>
              <option value="Part-time">Part-time</option>
            </select>

            <select
              name="domain"
              value={filters.domain}
              onChange={handleChangeFilter}
            >
              <option value="All">All</option>
              <option value="Language Specialist">Language Specialist</option>
              <option value="Management">Management</option>
              {/* Add more domain options as needed */}
            </select>
         
        </div>
        {filteredJobs.map((job) => (
            <JobCard key={job.id} job={job} />
        ))}
      </Container>
      <Footer />
    </div>
  );
};

export default Careers;
