import React, { useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import BlogCard from "../components/blogcard.js";
import blogData from "../components/blogData.json";
import Header from "../components/header";
import Footer from "../components/footer";
import "../styles/blogs.css";
import filterimg from "../images/filter.svg";
import insta from "../images/insta-blue.svg";
import twtr from "../images/twtr-blue.svg";
import fb from "../images/fb-blue.svg";
import lkdn from "../images/lkdn-blue.svg";
import yt from "../images/yt-blue.svg";
import google from "../images/google-blue.svg";
import FeaturedBlogCard from "../components/featuredblogcard.js";
import dropdown from "../images/dropdown.svg";

const Blogs = () => {
  const [filter, setFilter] = useState("Study Programs");
  const [sortOrder, setSortOrder] = useState("asc"); // Default sort order is ascending
  const [isRotated, setIsRotated] = useState(false);

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };
  const handleSelectClick = () => {
    setIsRotated(!isRotated);
  };

  const handleSortToggle = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  // Get unique blog types for dropdown options
  const blogTypes = [...new Set(blogData.map((blog) => blog.type))];

  // Filter blogs based on selected type
  let filteredBlogs = blogData.filter(
    (blog) => !filter || blog.type === filter
  );

  // Sort filtered blogs based on sort order and title
  filteredBlogs.sort((a, b) => {
    if (sortOrder === "asc") {
      return a.title.localeCompare(b.title);
    } else {
      return b.title.localeCompare(a.title);
    }
  });

  const featuredBlogs = blogData.filter((item) => {
    return item.featured === true;
  });
  const studentBlogs = blogData.filter((item) => {
    return item.student === true;
  });


  return (
    <div className="sec-start">
      <Header />
      <h1 className="text-center pt-4 pt-md-0">Check Out Our Blogs</h1>
      <p className="mx-auto text-center px-3 px-md-0" style={{ maxWidth: "704px" }}>
      Read about the francophone countries, the trends in the hospitality industry, India’s relation with the french language, facts about the world abroad, the success and challenges abroad, ongoing relatable debates and much more.
      </p>
      <div className="container mt-5 mb-5">
        <Row className="mb-4">
          <Col md={8}>
            <div className="blogs-filter">
              <div
                className={`custom-select-container ${isRotated ? "open" : ""}`}
                style={{maxWidth:"200px"}}
              >
                <Form.Control
                  as="select"
                  value={filter}
                  onChange={handleFilterChange}
                  onClick={handleSelectClick}
                >
                  <option value="">Select a type</option>
                  {blogTypes.map((type, index) => (
                    <option key={index} value={type}>
                      {type}
                    </option>
                  ))}
                </Form.Control>
                <img
                  src={dropdown}
                  alt="dropdown"
                  className={`dropdown-icon ${isRotated ? "rotate" : ""}`}
                />
              </div>
              {sortOrder === "asc" ? (
                <div style={{padding:"0 15px"}}>
                  <img
                    src={filterimg}
                    className="desc-filter filter"
                    alt="descending"
                    onClick={handleSortToggle}
                  />
                  <p>Sort in descending order</p>
                </div>
              ) : (
                <div>
                  <img
                    src={filterimg}
                    className="filter"
                    alt="ascending"
                    onClick={handleSortToggle}
                  />
                  <p>Sort in ascending order</p>
                </div>
              )}
            </div>
            {filteredBlogs.map((blog) => (
              <BlogCard key={blog.id} blog={blog} />
            ))}
          </Col>
          <Col md={4}>
            <div className="featured-blogs">
              <h2>Featured Blogs</h2>
              {featuredBlogs.map((fblog) => (
                <FeaturedBlogCard key={fblog.id} blog={fblog} />
              ))}
            </div>
            <div className="student-blogs mt-4">
              <h2>For Students</h2>
              {studentBlogs.map((fblog) => (
                <FeaturedBlogCard key={fblog.id} blog={fblog} />
              ))}
            </div>
            <div className="blog-contact-us">
              <h2>Connect With Us</h2>
              <div className="sm-icons-blog">
                <a href="https://www.instagram.com/alzeaindia/?hl=en" rel="noreferrer" target="_blank"><img src={insta} alt="social icon" /></a>
                <a href="https://twitter.com/alzeaindia?lang=en" rel="noreferrer" target="_blank"><img src={twtr} alt="social icon" /></a>
                <a href="https://www.linkedin.com/company/alzea-india/" rel="noreferrer" target="_blank"><img src={lkdn} alt="social icon" /></a>
                <a href="https://www.facebook.com/ALZEA.internships/" rel="noreferrer" target="_blank"><img src={fb} alt="social icon" /></a>
                <a href="https://www.youtube.com/channel/UCeO6Aa3Kv20zFb0OFgT2zbQ" rel="noreferrer" target="_blank"><img src={yt} alt="social icon" /></a>
                <a href="https://g.co/kgs/79SgR6y" rel="noreferrer" target="_blank"><img src={google} alt="social icon" /></a>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </div>
  );
};

export default Blogs;
