import { React, useState, useEffect, useRef } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import banner from "../images/Study-programs-banner.webp";
import { Row, Col, Container } from "react-bootstrap";
import "../styles/studyprograms.css";
import francetabimg from "../images/francetab.webp";
import Accordioncomp from "../components/accordion";
import StudyInCountries from "../images/Study In Non-Francophone Countries.webp";
import blogData from "../components/blogData.json";
import BlogCarousel from "../components/blogcarousel";
import Accordionmain from "../components/accordion";
import arrowWhite from "../images/arrow-white.svg";
import formimg from "../images/form-img.png";
import axios from "axios";
import { Link } from "react-router-dom";
import clientsData from "../components/clientsData.json";
import CarouselCards from "../components/carousel";
import whygothroughus from "../images/why-go-through-us-study-programs.webp";
import processArrow from "../images/Process-arrow.svg";

function Studyprograms() {
  const countrytabobj = {
    france: {
      image: francetabimg,
      title: "Why Study In France",
      text:
        "Renowned as a hub for business and luxury sectors, France is also the first country to sign a mutual recognition agreement with India. A degree from France not only enhances your chances to work abroad but also opens doors to numerous opportunities in India. Since you come from a different culture, your different perspectives on life are warmly welcomed in France. The French government prioritises students, offering numerous benefits to alleviate financial and educational stress. Your academic journey in France promises a network of a large alumni community along with travel experiences and lifelong friendships. Experience the richness of French culture and education, and embark on a journey of discovery and success in one of the world's most dynamic and vibrant countries.",
    },
    canada: {
      image: francetabimg,
      title: "Why Study In Canada",
      text:
        "Considering studying in Canada? You're in for a treat! Canada is renowned for its breathtaking natural landscapes, vibrant multicultural cities, and warm hospitality. Not only does Canada offer top-notch education at a reasonable cost, but it also provides a high quality of life and ample work opportunities during and after your studies. With its welcoming atmosphere and generous work permit policies, Canada truly stands out as an ideal destination for international students. Welcome to the Great White North!",
    },
  };
  const [activeTab, setActiveTab] = useState("france");

  const handleClick = (tab) => {
    setActiveTab(tab);
  };

  const programs = (activeTab==="france") ? {
    ug: {
      stream1: {
        title: "Bachelors in Hotel Management",
        text:
          "Culinary And Pastry Arts, Tourism",
      },
      stream2: {
        title: "Bachelors In Science",
        text:
          "Technology, It Engineering, Mathematics, Geography",
      },
      stream3: {
        title: "Bachelors In Luxury",
        text:
          "Management, Fashion, Design, Marketing",
      },
      stream4: {
        title: "Bachelors In Arts",
        text:
          "Humanities, Literature, Languages, Philosophy, History, Psychology",
      },
    },
    pg: {
      stream1: {
        title: "Masters in HM",
        text:
          "Lorem ipsum dolor sit amet consectetur. Id nunc nibh eget adipiscing lectus quam habitasse facilisis. ",
      },
      stream2: {
        title: "Masters in Fashion",
        text:
          "Lorem ipsum dolor sit amet consectetur. Id nunc nibh eget adipiscing lectus quam habitasse facilisis. ",
      },
      stream3: {
        title: "Masters in Business Management",
        text:
          "Lorem ipsum dolor sit amet consectetur. Id nunc nibh eget adipiscing lectus quam habitasse facilisis. ",
      },
      stream4: {
        title: "Masters in Tourism",
        text:
          "Lorem ipsum dolor sit amet consectetur. Id nunc nibh eget adipiscing lectus quam habitasse facilisis. ",
      },
    },
  } : {
    ug: {
      stream1: {
        title: "Bachelors In Science",
        text:
          "Engineering, Medicine",
      },
      stream2: {
        title: "Bachelors In Arts",
        text:
          "Languages",
      },
      stream3: {
        title: "Bachelors In Law",
        text:
          "Management, Fashion, Design, Marketing",
      },
      stream4: {
        title: "Bachelors In Business",
        text:
          "Economics",
      },
    },
    pg: {
      stream1: {
        title: "Masters in Hotel Management",
        text:
          "Lorem ipsum dolor sit amet consectetur. Id nunc nibh eget adipiscing lectus quam habitasse facilisis. ",
      },
      stream2: {
        title: "Masters in Fashion",
        text:
          "Lorem ipsum dolor sit amet consectetur. Id nunc nibh eget adipiscing lectus quam habitasse facilisis. ",
      },
      stream3: {
        title: "Masters in Business Management",
        text:
          "Lorem ipsum dolor sit amet consectetur. Id nunc nibh eget adipiscing lectus quam habitasse facilisis. ",
      },
      stream4: {
        title: "Masters in Tourism",
        text:
          "Lorem ipsum dolor sit amet consectetur. Id nunc nibh eget adipiscing lectus quam habitasse facilisis. ",
      },
    },
  };
  const [programtab, setProgramtab] = useState("ug");

  const accordionitems = (activeTab==="france") ? [
    {
      title: "Intakes",
      value:
        "French universities typically offer two intakes every year – the January intake (Spring intake) and the September intake (Fall intake). However, the availability of intakes may vary depending on the institution and the specific program of study.",
    },
    {
      title: "Program Processing Time",
      value:
        "Universities usually announce admission decisions within 4-8 weeks of applying, with notifications sent via email. Additional time should be factored in for the application and visa process.",
    },
    {
      title: "Estimated Expense",
      value:
        "Studying in France is remarkably affordable, with government subsidies for international students in higher public institutions. Bachelor's programs in public institutions cost approximately 4000 euros per year. It's important to consider overall living costs as well when budgeting for your studies.",
    },
  ] : [
    {
      title: "Intakes",
      value:
        "Canada generally offers three main intakes for international students: Fall intake (September), Winter intake (January/February), and Summer intake (May/June). However, the availability of intakes may vary depending on the institution and the specific program of study.",
    },
    {
      title: "Program Processing Time",
      value:
        "The processing time for programs in Canada typically ranges from 6 to 8 months.",
    },
    {
      title: "Estimated Expense",
      value:
        "The estimated expenses for studying in Canada range from 21,000 CAD to 40,000 CAD per year, covering tuition fees and living costs.",
    },
  ];

  const [clientcardItems, setclientscardItems] = useState([]);

  useEffect(() => {
    if (clientsData) {
      const importclientImages = async () => {
        const clientimages = await Promise.all(
          clientsData.map(async (items) => {
            const imageModule = await import(`../images/${items.image}`);
            return imageModule.default;
          })
        );

        setclientscardItems(
          clientsData.map((items, index) => ({
            id: items.id,
            name: items.name,
            image: clientimages[index],
            info: items.info,
          }))
        );
      };
      importclientImages();
    }
  }, []);

  const [BlogsForCarousel, setBlogsForCarousel] = useState([]);

  useEffect(() => {
    if (blogData) {
      const importImages = async () => {
        const images = await Promise.all(
          blogData.map(async (data) => {
            const imageModule = await import(`../images/${data.image}`);
            return imageModule.default;
          })
        );
        setBlogsForCarousel(
          blogData.map((data, index) => ({
            id: data.id,
            title: data.title,
            image: images[index],
            date: data.date,
            type: data.type,
            style: data.styles,
          }))
        );
      };
      importImages();
    }
  }, []);

  const faqItems = [
    {
      title: "What study programs does ALZEA INDIA offer?",
      value:
        "ALZEA INDIA provides a range of study programs catering to various aspects of the French language and culture. These include language immersion programs, academic courses, and specialized programs tailored to your interests.",
    },
    {
      title:
        "Do we offer study programs in non-francophone countries?",
      value:
        "Yes, we provide study programs in both francophone and non-francophone countries. For detailed information on programs in non-francophone countries, kindly get in touch with us directly or visit our sister company's website at www.destinycalling.in.",
    },
  ];

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    college: "",
    query: "",
  });

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .post("http://localhost:3001/studyprogramsform", formData) // Pass formData directly
      .then((res) => {
        console.log("registered successfully");
        setFormData({
          name: "",
          email: "",
          mobileNumber: "",
          college: "",
          query: "",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  
  const [itemImages, setItemImages] = useState([]);
  useEffect(() => {
    const fetchImages = async () => {
      const images = await Promise.all(
        process.map(async (item) => {
          const itemImage = await import(`../images/${item}.svg`);
          return { item, image: itemImage.default };
        })
      );
      setItemImages(images);
    };
    const process = [
      "Registration",
      "Interview",
      "Application",
      "Acceptance",
      "Visa Processing",
      "Fly High",
    ];
    fetchImages();
  }, []);

  const targetDivRef = useRef(null);

  // Function to handle scrolling to the target div
  const scrollToDiv = () => {
    const targetDivPosition = targetDivRef.current.getBoundingClientRect().top;
    window.scrollTo({
      top: targetDivPosition - 100,
      behavior: 'smooth'
    });
  };


  return (
    <div className="sec-start">
      <Header />
      <Container>
        <Row className="pb-5">
          <Col md="6" style={{ margin: "auto 0" }}>
            <h1>Study Programs</h1>
            <p>
            Embarking on a study program abroad is a transformative experience, shaping individuals, broadening perspectives, and fostering resilience through overcoming challenges. Being immersed in a multicultural environment encourages confident expression of thoughts and ideas, fostering innovation and global connections. Studying in francophone countries adds an enriching layer to this journey, with their vibrant culture, rich history, and renowned gastronomy. French, spoken on every continent besides English, has become essential in the global business landscape. At ALZEA INDIA, we offer comprehensive study programs in francophone countries, guiding you through the entire process with inclusive services, mock interviews, and continuous support from experienced program managers. From navigating bureaucracy to cultural integration, we ensure a seamless and enriching experience, nurturing personal and professional growth every step of the way.
            </p>
            <button type="Submit" onClick={scrollToDiv}>Apply Now</button>
          </Col>
          <Col md="6 pt-5">
            <img
              src={banner}
              style={{ width: "100%" }}
              alt="Study Programs Banner"
            />
          </Col>
        </Row>
        <Row className="pb-5">
          <div className="countrymaintab">
            <span
              className={`countrytab ${activeTab === "france" && "active"}`}
              onClick={() => handleClick("france")}
            >
              France
            </span>
            <span
              className={`countrytab ${activeTab === "canada" && "active"}`}
              onClick={() => handleClick("canada")}
            >
              Canada
            </span>
          </div>
          <Col md="6 pt-5">
            <img
              src={countrytabobj[activeTab].image}
              style={{ width: "100%" }}
              alt="Country"
            />
          </Col>
          <Col md="6 pt-5"style={{margin:"auto"}}>
            <h2>{countrytabobj[activeTab].title}</h2>
            <p>{countrytabobj[activeTab].text}</p>
          </Col>
        </Row>
        <div className="mt-5 mb-5">
          <h2 className="text-center pb-4">Process Flow</h2>
          <div className="d-flex process">
            {itemImages.map(({ item, image }, index) => (
              <div key={item} style={{ display: "flex" }}>
                <div className="process-content" >
                  <img src={image} style={{ width: "40px" }} alt={item} />
                  <span style={{ fontWeight: "bold" }}>{item}</span>
                </div>
                {itemImages.length - 1 !== index && (
                  <img
                    src={processArrow}
                    style={{ width: "32px" }}
                    alt="Process arrow"
                  />
                )}
              </div>
            ))}
          </div>
        </div>
        <Row>
          <div className="programsmaintab">
            <span
              className={`programstab ${programtab === "ug" && "active"}`}
              onClick={() => {
                setProgramtab("ug");
              }}
            >
              UG/Bachelors
            </span>
            <span
              className={`programstab ${programtab === "pg" && "active"}`}
              onClick={() => {
                setProgramtab("pg");
              }}
            >
              PG/Masters
            </span>
          </div>
          <Col md="6" className="p-md-4 p-sm-2">
            <h3>{programs[programtab].stream1.title}</h3>
            <p>{programs[programtab].stream1.text}</p>
          </Col>
          <Col md="6" className="p-md-4 p-sm-2">
            <h3>{programs[programtab].stream2.title}</h3>
            <p>{programs[programtab].stream2.text}</p>
          </Col>
          <Col md="6" className="p-md-4 p-sm-2">
            <h3>{programs[programtab].stream3.title}</h3>
            <p>{programs[programtab].stream3.text}</p>
          </Col>
          <Col md="6" className="p-md-4 p-sm-2">
            <h3>{programs[programtab].stream4.title}</h3>
            <p>{programs[programtab].stream4.text}</p>
          </Col>
        </Row>
        <Row className="sprograms-accordion">
          <Accordioncomp Items={accordionitems} />
        </Row>
      </Container>
      <div className="pt-5 pb-5">
        <h2 className="text-center mx-auto" style={{ maxWidth: "625px" }}>
          Study In Non-Francophone Countries
        </h2>
        <div
          className="text-center mx-auto pt-4 pb-4"
          style={{ maxWidth: "625px" }}
        >
          <p>
          For those interested in studying in non-Francophone countries, we invite you to explore opportunities with our sister company, Destiny Calling. From comprehensive advice to personalised assistance, Destiny Calling is your gateway to educational opportunities worldwide. 
          </p>
          <button type="submit"><a href="https://destinycalling.in/" rel="noreferrer" target="_blank">Know More</a></button>
        </div>
        <img
          src={StudyInCountries}
          style={{ width: "100%" }}
          alt="Study in francophone countries"
        />
      </div>
      <Container>
        <Row className="pt-5 pb-5">
          <Col md="6">
            <img
              src={whygothroughus}
              style={{ width: "100%" }}
              alt="Why Go Through Us to Study Abroad"
            />
          </Col>
          <Col md="6" style={{ margin: "auto 0", padding: "20px" }}>
            <h2>Why go Through Us to study abroad?</h2>
            <p>
            Choosing to study abroad is a significant decision, and we understand the importance of finding the right support system to navigate this exciting journey. At ALZEA INDIA, we provide personalised guidance and unwavering support to ensure a smooth experience. We streamline the entire process, from application to arrival, alleviating any concerns or uncertainties along the way. Our dedicated team is here to address your needs and preferences, tailoring each step of your journey to suit your individual goals. From securing admissions to arranging accommodations and cultural immersion experiences, we go above and beyond to make your study abroad dreams a reality. Trust us to be your partner, and embark on a life-changing experience with confidence and peace of mind.
            </p>
          </Col>
        </Row>
      </Container>
      <div className="container mt-5 mb-5 carousel">
        <div className="carousel-content ">
          <div>
            <h2>What Our Clients Say About Us</h2>
            <p>
              Lorem ipsum dolor sit amet consectetur. Aliquet tempus mollis dis
              adipiscing ipsum egestas purus quam etiam.
            </p>
          </div>
        </div>
        <CarouselCards data={clientcardItems} />
      </div>
      <Container>
        <Row className="faq-study-programs">
          <Col md="12" lg="4">
            <h2>Frequently Asked Questions</h2>
            <p>
              Lorem ipsum dolor sit amet consectetur. In ut nec feugiat vitae
              vitae iaculis risus ugiat vitae vitae iaculis risus
            </p>
            <Link to="/faqs" className="view-btn">
              View All
              <img className="arrowIcon" alt="" src={arrowWhite}></img>
            </Link>
          </Col>
          <Col md="12" lg="8">
            <Accordionmain Items={faqItems} />
          </Col>
        </Row>
        <div className="mt-5 mb-5">
          <h2>Check out These Blogs</h2>
          <p style={{maxWidth:"1200px"}}>
          Read about the francophone countries, the trends in the hospitality industry, India’s relation with the french language, facts about the world abroad, the success and challenges abroad, ongoing relatable debates and much more.
          </p>
          <BlogCarousel data={BlogsForCarousel} />
        </div>
        <Row className="pt-5 pb-5" ref={targetDivRef}>
          <Col sm="12" md="12" lg="6">
            <h2>Let’s Connect</h2>
            <img src={formimg} style={{width:"100%"}} className="form-img" alt="Fill the form" />
          </Col>
          <Col sm="12" md="12" lg="6">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="name">
                    Name:{" "}
                    <span style={{ color: "red", fontSize: "20px" }}>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="John Doe"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="email">
                    Email:{" "}
                    <span style={{ color: "red", fontSize: "20px" }}>*</span>
                  </label>
                  <input
                    type="tel"
                    placeholder="johndoe@abc.com"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="mobileNumber">
                    Mobile Number:{" "}
                    <span style={{ color: "red", fontSize: "20px" }}>*</span>
                  </label>
                  <input
                    type="tel"
                    placeholder="829-983-1929"
                    id="mobileNumber"
                    name="mobileNumber"
                    value={formData.mobileNumber}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="college">
                    Your College:{" "}
                    <span style={{ color: "red", fontSize: "20px" }}>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="New Horizon College"
                    id="college"
                    name="college"
                    value={formData.college}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <label htmlFor="query">
                Query: <span style={{ color: "red", fontSize: "20px" }}>*</span>
              </label>
              <textarea
                rows="4"
                cols="50"
                placeholder="Leave a message"
                id="query"
                name="query"
                value={formData.query}
                onChange={handleChange}
              ></textarea>

              <button type="submit">Submit</button>
            </form>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default Studyprograms;
