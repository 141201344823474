import React, { useState, useEffect } from "react";
import checkbox from "../images/checkbox.png";
import { Col, Row } from "react-bootstrap";
import HTMLReactParser from "html-react-parser";

function CardsAccordion(props) {
  
    const cards = props.data;
    const [opencards, setOpencards] = useState(Array(cards.length).fill(false));
    
    useEffect(() => {
        setOpencards(prevOpencards => {
            const initialOpencards = [...prevOpencards];
            initialOpencards[0] = true;
            return initialOpencards;
        });
    }, []);
    
    const handleClick = (index) => {
        const updatedOpencards = [...opencards];
        updatedOpencards[index] = !updatedOpencards[index];
        setOpencards(updatedOpencards);
    };
    
    return (
        <>
            {cards.map((item, index) => {
                const style = {
                    display: opencards[index] ? "block" : "none"
                };
                return (
                    <Row className="cardsAccordion" onClick={() => handleClick(index)} key={index}>
                        <Col lg="1">
                            <img src={checkbox} alt="checkbox" />
                        </Col>
                        <Col lg="10">
                            <h4>{item.title}</h4>
                            <p style={style}>{HTMLReactParser(item.para)}</p>
                        </Col>
                    </Row>
                );
            })}
        </>
    );
    
}

export default CardsAccordion;
