// BlogCard.js
import { React} from 'react';
import { Row } from 'react-bootstrap';
import { Link } from 'react-router-dom'; // Import Link from React Router

const BlogCard = ({blog}) => {
    

  return (
      <Link to={`/blog/${blog.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
        <Row className='blogcard mb-5'>
            <h3>{blog.title}</h3>
            <p style={blog.styles}>{blog.type}</p>
        </Row>
      </Link>
  );
};

export default BlogCard;
