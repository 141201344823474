import { React, useEffect, useState, useRef } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import whydointernshipwithus from "../images/why-do-an-internships-page.webp";
import blogData from "../components/blogData.json";
import BlogCarousel from "../components/blogcarousel";
import Accordionmain from "../components/accordion";
import arrowWhite from "../images/arrow-white.svg";
import formimg from "../images/form-img.png";
import axios from "axios";
import { Link } from "react-router-dom";
import clientsData from "../components/clientsData.json";
import CarouselCards from "../components/carousel";
import { Row, Col, Container } from "react-bootstrap";
import gift from "../images/gift.png";
import internoptionimg from "../images/internship-option.webp";
import banner from "../images/internship-banner.webp";
// import thumbnail from "../images/Thumbnail.png";
// import video from "../files/testimonials.mp4";
import learnmore from "../images/learn-more.svg";
import france from '../images/eiffel-red.png';
import mauritius from '../images/mauritius-option.png';

const Studyinternships = () => {
  const [clientcardItems, setclientscardItems] = useState([]);

  useEffect(() => {
    if (clientsData) {
      const importclientImages = async () => {
        const clientimages = await Promise.all(
          clientsData.map(async (items) => {
            const imageModule = await import(`../images/${items.image}`);
            return imageModule.default;
          })
        );

        setclientscardItems(
          clientsData.map((items, index) => ({
            id: items.id,
            name: items.name,
            image: clientimages[index],
            info: items.info,
          }))
        );
      };
      importclientImages();
    }
  }, []);

  const [BlogsForCarousel, setBlogsForCarousel] = useState([]);

  useEffect(() => {
    if (blogData) {
      const importImages = async () => {
        const images = await Promise.all(
          blogData.map(async (data) => {
            const imageModule = await import(`../images/${data.image}`);
            return imageModule.default;
          })
        );
        setBlogsForCarousel(
          blogData.map((data, index) => ({
            id: data.id,
            title: data.title,
            image: images[index],
            date: data.date,
            type: data.type,
            style: data.styles,
          }))
        );
      };
      importImages();
    }
  }, []);

  const faqItems = [
    {
      title: "Is knowledge of French mandatory for internships or work permits in France or French-speaking countries like Mauritius?",
      value:
        "While not always a strict requirement, knowing French can be beneficial. We offer a complimentary in-house French language course to support interns, enhancing their language skills and competitiveness. For work permits in France, basic French knowledge is necessary, and some properties may require certification.",
    },
    {
      title:
        "Are there any complimentary services included?",
      value:
        "Yes, in addition to the internship / work permit, we offer complimentary services to enrich the applicant's experience. These include the FitInsideOut Program, language courses, and Counseling and Therapy Support, fostering personal and professional growth. Some come at an extra cost or are included in the advantages of our premium program packages.",
    },
  ];

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    college: "",
    query: "",
  });

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .post("http://localhost:3001/internshipformfillup", formData) // Pass formData directly
      .then((res) => {
        console.log("registered successfully");
        setFormData({
          name: "",
          email: "",
          mobileNumber: "",
          college: "",
          query: "",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const benefits = [
    {
      title: "Work Opportunities Abroad",
      text:
        "Interning abroad opens doors to more job opportunities worldwide.",
    },
    {
      title: "Network Building",
      text:
        "Make valuable connections with people from around the world during your internship.",
    },
    {
      title: "Resume Enhancement",
      text:
        "Elevate your resume with international experience, showcasing adaptability and global perspective.",
    },
    {
      title: "Cultural Exposure and Travel Opportunities",
      text:
        "Immerse yourself in diverse cultures and seize the chance to explore new destinations, enriching both your personal and professional growth.",
    },
  ];

  // const [controlsVisible, setControlsVisible] = useState(false);
  // const videoRef = useRef(null);
  // const handleVideoClick = () => {
  //   setControlsVisible(true);
  //   const videoElement = videoRef.current;
  //   // Reset currentTime to 0 if video has ended
  //   if (videoElement.ended) {
  //     videoElement.currentTime = 0;
  //   }
  //   // Play the video
  //   if (videoElement) {
  //     videoElement.play().catch((error) => {
  //       console.error("Error playing video:", error);
  //     });
  //   }
  // };

  const targetDivRef = useRef(null);

  // Function to handle scrolling to the target div
  const scrollToDiv = () => {
    const targetDivPosition = targetDivRef.current.getBoundingClientRect().top;
    window.scrollTo({
      top: targetDivPosition - 100,
      behavior: 'smooth'
    });
  };

  return (
    <div className="sec-start">
      <Header />
      <Container>
        <Row>
          <Col md="6" style={{ margin: "auto 0" }}>
            <h1>Study Internships</h1>
            <p>
            At ALZEA INDIA, we offer hospitality students holistic internship experiences in
            Francophone countries, primarily France and Mauritius. Renowned as havens for the
            hotel management industry, France stands as the gastronomical capital of the world,
            while Mauritius thrives in luxurious fine experiences.
            </p>
            <p>Internships span across every domain within the hotel management industry;
            culinary arts, pastry and bakery, food and beverage service, front office operations,
            housekeeping, bartending, and flair bartending.</p>
            <button type="Submit" onClick={scrollToDiv}>Apply Now</button>
          </Col>
          <Col md="6 pt-5">
            <img
              src={banner}
              style={{ width: "100%" }}
              alt="Study Internships Banner"
            />
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <img
              src={internoptionimg}
              alt="Internship Options"
              style={{ width: "100%" }}
            />
          </Col>
          <Col md="6" style={{margin:"auto 0"}}>
            <div className="options">
              <img src={france} alt="France Eiffel Tower" style={{height:"44px"}} />
              <div className="option-content">
                <p style={{fontWeight:"bold", fontSize:"24px"}}>France</p>
                <p>
                Embark on a six-month internship in France—a dream come true for every aspiring
                hotel management student. Immerse yourself in picturesque locales straight out of a
                movie, where you&#39;ll master the art of French culinary techniques with precision. This
                experience not only opens doors to countless opportunities across Europe but also
                ignites the entrepreneurial spirit within you, to start a venture of your own in your
                country inspired by your experience.
                </p>
                <Link to="/internships-in-france" className="learnMoreButton">
                  <div className="buttonText">Learn More</div>
                  <img className="arrowIcon" alt="" src={learnmore} />
                </Link>
              </div>
            </div>
            <div className="options">
            <img src={mauritius} alt="Mauritius" style={{height:"44px"}} />
              <div className="option-content">
                <p style={{fontWeight:"bold", fontSize:"24px"}}>Mauritius</p>
                <p>
                Kickstart your career in the hospitality industry with a six to twelve-month internship
                in Mauritius, the epitome of luxury and hospitality excellence. As a French-speaking
                nation, Mauritius broadens your horizons for future internships and job opportunities
                in other French-speaking countries. Often a first international internship experience
                for many, Mauritius serves as a solid foundation to refine your skills in a culturally
                diverse yet familiar environment, often referred to as &quot;mini India&quot;
                </p>
                <Link to="/internships-in-mauritius" className="learnMoreButton">
                  <div className="buttonText">Learn More</div>
                  <img className="arrowIcon" alt="" src={learnmore} />
                </Link>
              </div>
            </div>
          </Col>
        </Row>
        <h2 className="text-center pt-5">Benefits Of The Internship</h2>
        <Row style={{ gap: "40px", marginTop: "40px" }}>
          {benefits.map((item) => {
            return (
              <Col md="6" lg="3" className="benefits-card">
                <img src={gift} style={{ width: "72px" }} alt="gift" />
                <p style={{ fontSize: "18px", fontWeight: "bold" }}>
                  {item.title}
                </p>
                <p>{item.text}</p>
              </Col>
            );
          })}
        </Row>
        {/* <h2 className="text-center pt-5">Video Messages By Students</h2>
        <div className="student-testimonials">
          <video
            ref={videoRef}
            controls={controlsVisible}
            className="container"
            onClick={handleVideoClick}
            onEnded={() => {
              setControlsVisible(false);
            }}
            poster={thumbnail}
          >
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div> */}
        <Row className="pt-5 pb-5">
          <Col md="6">
            <img
              src={whydointernshipwithus}
              style={{ width: "100%" }}
              alt="Why do an Internship through Us?"
            />
          </Col>
          <Col md="6" style={{ margin: "auto 0", padding: "20px" }}>
            <h2>Why do an Internship through Us?</h2>
            <p>
            At ALZEA INDIA, we prioritize your comfort and success every step of the way. Enjoy
            the convenience of complimentary accommodation and duty meals during your
            internship, ensuring a seamless and enriching experience. From start to finish, our
            dedicated team provides end-to-end support, guiding you through every aspect of
            your journey. Immerse yourself in the French language with our complimentary
            language services, enhancing both your professional and personal development.
            Additionally, take advantage of our free counseling and consultancy services,
            empowering you to navigate challenges and make informed decisions. With access
            to renowned four or five-star properties, you&#39;ll gain hands-on experience in
            esteemed establishments, setting the stage for a rewarding career in the hospitality
            industry. Choose ALZEA INDIA for an internship experience that goes above and
            beyond, preparing you for success in a globalized world.
            </p>
          </Col>
        </Row>
      </Container>
      <div className="container mt-5 mb-5 carousel">
        <div className="carousel-content">
          <div>
            <h2>What Our Clients Say About Us</h2>
            <p>
              Lorem ipsum dolor sit amet consectetur. Aliquet tempus mollis dis
              adipiscing ipsum egestas purus quam etiam.
            </p>
          </div>
        </div>
        <CarouselCards data={clientcardItems} />
      </div>
      <Container>
        <Row className="faq-study-programs">
          <Col md="12" lg="4">
            <h2>Frequently Asked Questions</h2>
            <p>
              Lorem ipsum dolor sit amet consectetur. In ut nec feugiat vitae
              vitae iaculis risus ugiat vitae vitae iaculis risus
            </p>
            <Link to="/faqs" className="view-btn">
              View All
              <img className="arrowIcon" alt="" src={arrowWhite}></img>
            </Link>
          </Col>
          <Col md="12" lg="8">
            <Accordionmain Items={faqItems} />
          </Col>
        </Row>
        <div className="mt-5 mb-5">
          <h2>Check out These Blogs</h2>
          <p>
            Lorem ipsum dolor sit amet consectetur. In ut nec feugiat vitae
            vitae iaculis risus ugiat vitae vitae iaculis risus
          </p>
          <BlogCarousel data={BlogsForCarousel} />
        </div>
        <Row className="pt-5 pb-5"  ref={targetDivRef}>
          <Col sm="12" md="12" lg="6">
            <h2>Let’s Connect</h2>
            <img src={formimg} style={{width:"100%"}}  className="form-img" alt="Fill the form" />
          </Col>
          <Col sm="12" md="12" lg="6">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="name">
                    Name:{" "}
                    <span style={{ color: "red", fontSize: "20px" }}>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="John Doe"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="email">
                    Email:{" "}
                    <span style={{ color: "red", fontSize: "20px" }}>*</span>
                  </label>
                  <input
                    type="tel"
                    placeholder="johndoe@abc.com"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="mobileNumber">
                    Mobile Number:{" "}
                    <span style={{ color: "red", fontSize: "20px" }}>*</span>
                  </label>
                  <input
                    type="tel"
                    placeholder="829-983-1929"
                    id="mobileNumber"
                    name="mobileNumber"
                    value={formData.mobileNumber}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="college">
                    Your College:{" "}
                    <span style={{ color: "red", fontSize: "20px" }}>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="New Horizon College"
                    id="college"
                    name="college"
                    value={formData.college}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <label htmlFor="query">
                Query: <span style={{ color: "red", fontSize: "20px" }}>*</span>
              </label>
              <textarea
                rows="4"
                cols="50"
                placeholder="Leave a message"
                id="query"
                name="query"
                value={formData.query}
                onChange={handleChange}
              ></textarea>

              <button type="submit">Submit</button>
            </form>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default Studyinternships;
