import { React, useEffect, useRef, useState } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { Row, Col, Container } from "react-bootstrap";
import bannerimg from "../images/study-trips-banner.webp";
import studyTripsTipimg from "../images/Study-trips-tips.webp";
import offertrips1 from "../images/offer-study-trips-1.webp";
import offertrips2 from "../images/offer-study-trips-2.webp";
import learnmore from "../images/learn-more.svg";
import { Link } from "react-router-dom";
import tripsframe from "../images/trips-frame.webp";
import WhyGoThroughUs from "../images/why-go-through-us.webp";
import howRweDiff from "../images/how-are-we-diff.webp";
import blogData from "../components/blogData.json";
import "../styles/studytrips.css";
import BlogCarousel from "../components/blogcarousel";
import Accordionmain from "../components/accordion";
import arrowWhite from "../images/arrow-white.svg";
import formimg from "../images/form-img.png";
import axios from "axios";

const Studytrips = () => {
  const [BlogsForCarousel, setBlogsForCarousel] = useState([]);

  useEffect(() => {
    if (blogData) {
      const importImages = async () => {
        const images = await Promise.all(
          blogData.map(async (data) => {
            const imageModule = await import(`../images/${data.image}`);
            return imageModule.default;
          })
        );
        setBlogsForCarousel(
          blogData.map((data, index) => ({
            id: data.id,
            title: data.title,
            image: images[index],
            date: data.date,
            type: data.type,
            style: data.styles,
          }))
        );
      };
      importImages();
    }
  }, []);

  const faqItems = [
    {
      title: "Where can anyone contact ALZEA INDIA for a prompt response?",
      value:
        "For prompt response to your queries or concerns, we recommend contacting us directly using the provided email ID or phone number. Our team will be more than happy to assist you and provide you with the information you need in a timely manner.",
    },
    {
      title:
        "Is knowledge of French mandatory for internships or work permits in France or French-speaking countries like Mauritius?",
      value:
        "While not always a strict requirement, knowing French can be beneficial. We offer a complimentary in-house French language course to support interns, enhancing their language skills and competitiveness. For work permits in France, basic French knowledge is necessary, and some properties may require certification.",
    },
  ];

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    college: "",
    query: "",
  });

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .post("http://localhost:3001/studytripsformfillup", formData) // Pass formData directly
      .then((res) => {
        console.log("registered successfully");
        setFormData({
          name: "",
          email: "",
          mobileNumber: "",
          college: "",
          query: "",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const targetDivRef = useRef(null);

  // Function to handle scrolling to the target div
  const scrollToDiv = () => {
    const targetDivPosition = targetDivRef.current.getBoundingClientRect().top;
    window.scrollTo({
      top: targetDivPosition - 100,
      behavior: 'smooth'
    });
  };

  return (
    <div className="sec-start">
      <Header />
      <Container>
        <Row className="pb-5">
          <Col lg="6" style={{ margin: "auto 0" }}>
            <h1>Study Trips</h1>
            <p>
            Our study trips transcend the ordinary—they're immersive journeys crafted to deepen your appreciation of the world's diverse cultures. From exploring unique towns in France to discovering French-inspired locales in India, we blend history, culture, and adventure to curate unforgettable experiences. Our goal isn't just to showcase iconic landmarks and beautiful scenery; it's to build genuine connections, lasting memories, and invaluable learnings. We believe that each destination has a story to tell, and our mission is to help you listen and learn from them.
            </p>
           <button type="Submit" onClick={scrollToDiv}>Apply Now</button>
          </Col>
          <Col lg="6" className="pt-5">
            <img
              src={bannerimg}
              style={{ width: "100%", maxHeight: "500px" }}
              alt=""
            />
          </Col>
        </Row>
        <Row className="g-0 pb-5">
          <Col md="6">
            <img
              src={studyTripsTipimg}
              style={{
                width: "100%",
                maxHeight: "500px",
                borderRadius: "10px",
              }}
              alt="Trips tips"
            />
          </Col>
          <Col
            md="6"
            style={{
              backgroundColor: "#F5F4F4",
              padding: "40px",
              borderRadius: "10px",
            }}
          >
            <h2>How a Study Trip is Different From general Trips?</h2>
            <p>
            While general trips often involve visiting popular attractions, indulging in delicious cuisine, and capturing photos at tourist hotspots, study trips offer a deeper immersion into the culture, heritage, and history of a destination. Each sightseeing stop becomes an opportunity to delve into the significance and stories behind it. For instance, when exploring a museum, participants don't just glance at paintings—they pause, analyse, and contemplate the artist's intentions, historical context, and the narrative portrayed. This approach sets the base for a profound connection to be built with the place and its culture, transforming sightseeing into an enriching educational experience. In essence, the "study" in study trips represents a commitment to understanding and appreciating the intricacies of each destination.
            </p>
          </Col>
        </Row>
        <Row className="pb-5">
          <h2 className="text-center pt-md-3 pb-4">
            Where Do We Offer Study Trips To?
          </h2>
          <Col md="6">
            <div className="offertrips1">
              <img
                src={offertrips1}
                style={{ width: "100%" }}
                alt="France Trips"
              />
              <Link to="/study-trips-in-france" className="offertrips1-link">
                <span>
                  France{" "}
                  <img src={learnmore} className="learn" alt="learn more" />
                </span>
              </Link>
            </div>
          </Col>
          <Col md="6 pt-md-0 pt-sm-5">
            <div className="offertrips2">
              <img
                src={offertrips2}
                style={{ width: "100%" }}
                alt="India Trips"
              />
              <Link to="/study-trips-in-india" className="offertrips2-link">
                <span>
                  India{" "}
                  <img src={learnmore} className="learn" alt="learn more" />
                </span>
              </Link>
            </div>
          </Col>
        </Row>
        <div className="pt-md-5 pb-5">
          <h2 className="text-center pt-3 pb-3">Images & Videos Of Trips</h2>
          <Container>
            <img src={tripsframe} style={{ width: "100%" }} alt="Trips Frame" />
          </Container>
        </div>
        <Row className="pb-5">
          <Col md="6">
            <img
              src={WhyGoThroughUs}
              style={{ width: "100%" }}
              alt="Why Go Through Us"
            />
          </Col>
          <Col md="6 pt-5">
            <h2>Why go Through Us?</h2>
            <p className="why-go-through-us-p">
              <span className="number">1</span>
              <span>
              <b>Personalized Itinerary:</b> We tailor-make itineraries based on your group's preferences, suggestions, and learning objectives for the chosen destination. Your expectations shape our plans, ensuring a truly customised travel experience.
              </span>
            </p>
            <p className="why-go-through-us-p">
              <span className="number">2</span>
              <span>
              <b>Streamlined Process:</b> From booking tickets to arranging accommodations and experiences, we handle every detail with precision. By noting your preferences, allergies, and dietary requirements in advance, we curate meals and accommodations to suit your needs, eliminating any last-minute hassles. Additionally, we secure registrations for experiences and workshops well in advance, ensuring a seamless and stress-free trip.
              </span>
            </p>
            <p className="why-go-through-us-p">
              <span className="number">3</span>
              <span>
              <b>Cultural Immersion:</b> Our trips go beyond mere sightseeing—they're immersive cultural experiences. Whether it's wearing the traditional attire, eating the local cuisine, staying with locals or engaging in carefully planned workshops, we bring history to life, allowing you to immerse yourself fully in the culture. From detailed descriptions to interactive walking tours, our trips do not just capture your senses but leave a taste of the culture quite literally on your taste buds.
              </span>
            </p>
          </Col>
        </Row>
        <Row className="pb-5">
          <Col md="6 pt-md-5 pt-sm-0 ">
            <h2>How Are We Different?</h2>
            <p className="why-go-through-us-p">
              <span className="number">1</span>
              <span>
              <b>Explore Offbeat Places:</b> We go beyond the typical tourist spots, uncovering hidden gems known only to locals. Our journeys take you off the beaten path, revealing the authentic essence of each destination.
              </span>
            </p>
            <p className="why-go-through-us-p">
              <span className="number">2</span>
              <span>
              <b>Home-stays & Workshops:</b> Immerse yourself in the culture firsthand by staying with locals during our trips. Engage in their language, learn their cuisine, and embrace their way of life. Our diverse array of activities, from cycling tours to sound healing workshops, allows for a deeper connection with the culture and heritage of each location.
              </span>
            </p>
            <p className="why-go-through-us-p">
              <span className="number">3</span>
              <span>
              <b>Flexibility and Inclusivity:</b> We prioritize personalized experiences, crafting itineraries tailored to your preferences in smaller, more intimate groups. Our trips are designed to accommodate diverse participants, welcoming individuals of different cultures, genders, races, and even pets. Embrace the freedom to explore and learn, regardless of who you are or where you come from.
              </span>
            </p>
          </Col>
          <Col md="6">
            <img
              src={howRweDiff}
              style={{ width: "100%" }}
              alt="How are we different?"
            />
          </Col>
        </Row>
        <Row className="faq-study-trips">
          <Col md="12" lg="4">
            <h2>Frequently Asked Questions</h2>
            <p>
              Lorem ipsum dolor sit amet consectetur. In ut nec feugiat vitae
              vitae iaculis risus ugiat vitae vitae iaculis risus
            </p>
            <Link to="/faqs" className="view-btn">
              View All
              <img className="arrowIcon" alt="" src={arrowWhite}></img>
            </Link>
          </Col>
          <Col md="12" lg="8">
            <Accordionmain Items={faqItems} />
          </Col>
        </Row>
        <div className="mt-5 mb-5">
          <h2>Check out These Blogs</h2>
          <p>
          Read about the francophone countries, the trends in the hospitality industry, India’s relation with the french language, facts about the world abroad, the success and challenges abroad, ongoing relatable debates and much more.
          </p>
          <BlogCarousel data={BlogsForCarousel} />
        </div>
        <Row className="pt-5 pb-5" ref={targetDivRef}>
          <Col sm="12" md="12" lg="6">
            <h2>Let’s Connect</h2>
            <img src={formimg} style={{width:"100%"}}  className="form-img" alt="Fill the form" />
          </Col>
          <Col sm="12" md="12" lg="6">
            <form onSubmit={handleSubmit} id="form">
              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="name">Name: <span style={{color:"red",fontSize:"20px"}}>*</span></label>
                  <input
                    type="text"
                    placeholder="John Doe"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="email">Email: <span style={{color:"red",fontSize:"20px"}}>*</span></label>
                  <input
                    type="tel"
                    placeholder="johndoe@abc.com"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="row">
              <div className="col-md-6">
                  <label htmlFor="mobileNumber">Mobile Number: <span style={{color:"red",fontSize:"20px"}}>*</span></label>
                  <input
                    type="tel"
                    placeholder="829-983-1929"
                    id="mobileNumber"
                    name="mobileNumber"
                    value={formData.mobileNumber}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="college">Your College: <span style={{color:"red",fontSize:"20px"}}>*</span></label>
                  <input
                    type="text"
                    placeholder="New Horizon College"
                    id="college"
                    name="college"
                    value={formData.college}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <label htmlFor="query">Query: <span style={{color:"red",fontSize:"20px"}}>*</span></label>
              <textarea
                rows="4"
                cols="50"
                placeholder="Leave a message"
                id="query"
                name="query"
                value={formData.query}
                onChange={handleChange}
              ></textarea>

              <button type="submit">Submit</button>
            </form>
          </Col>
        </Row>
      </Container>

      <Footer />
    </div>
  );
};

export default Studytrips;
