import React from 'react';
import '../styles/jobCard.css'; // Import CSS file for JobCard styles
import parse from 'html-react-parser';
import { Link } from "react-router-dom";


const JobCard = ({ job }) => {
  return (
    <div className="job-card">
      <div className="job-details">
        <div className="job-top">
          <span>{job.location} | {job.type}</span>
        </div>
        <h2 className="job-title">{job.title}</h2>
        <div className="designation">{job.designation}</div>
        <p>{parse(job.description)}</p>
       <button className="apply-now-btn"> <Link to="/contact" style={{width:"100px"}}>Apply Now</Link></button>
      </div>
    </div>
  );
};

export default JobCard;
