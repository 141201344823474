import React, { useEffect } from 'react';
import Slider from 'react-slick';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ArrowForward from '@mui/icons-material/ArrowForward';
import { useState } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Button, Col, Container, Row } from 'react-bootstrap';
import HTMLReactParser from 'html-react-parser';
import bluepattern from "../images/blue-pattern.svg";
import bluebg from "../images/card-bg.png";


const CustomNextArrow = ({ onClick }) => {
  const [clicked, setClicked] = useState(false);

  const handleButtonClick = () => {
    setClicked(!clicked);
    onClick();
  };

  return (
    <Button
      className={`custom-arrow custom-next-arrow${clicked ? ' clicked' : ''}`}
      onClick={handleButtonClick}
    >
      <ArrowForward />
    </Button>
  );
};

const CustomPrevArrow = ({ onClick }) => {
  const [clicked, setClicked] = useState(false);

  const handleButtonClick = () => {
    setClicked(!clicked);
    onClick();
  };

  return (
    <Button
      className={`custom-arrow custom-prev-arrow${clicked ? ' clicked' : ''}`}
      onClick={handleButtonClick}
    >
      <ArrowBack />
    </Button>
  );
};

const SingleCarousel = (props) => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    responsive: [
      {
        breakpoint: 767, // For tablets and smaller devices
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false
        },
      }
    ],
  };
  
  const Data = props.data;
  const [StudentsData, setStudentsData] = useState([]);
  
  useEffect(()=>{
    if(Data){
    const importimages = async ()=>{
        const images = await Promise.all(
            Data.map(async (item)=>{
                 const imageModule = await import(`../images/${item.image}`);
                 return imageModule.default;
            })
           );
           setStudentsData(
             Data.map((item, index)=>(
              {
              id:item.key,
              image:images[index],
              content:item.content,
              studentname:item.studentname
            }))
           );
         }
      importimages();
    }

  },[])
  

  return (
    
      <Container>
        <Slider {...settings}> 
        {StudentsData.map((item) => (
          <Row key={item.id} className='tab'>
               <Col md="4" >
                <img src={item.image} style={{ marginLeft:"20px", objectFit:"cover", aspectRatio:"3/4", height:"300px", width:"250px", position:"relative", zIndex:"9"}} alt={item.studentname} />
                <img src={bluepattern} className='blue-card-pattern'  style={{ width:"50px", position:"absolute",  zIndex:"99"}} alt='blue pattern' />
                <img src={bluebg} className='blue-card' alt='blue background' style={{position:"absolute",  height:"300px", width:"120px"}} />
               </Col>
               <Col md="8"  className='student-content'>
                <p style={{overflow:"auto", maxHeight:"250px"}}>{HTMLReactParser(item.content)}</p>
                <span>{item.studentname}</span>
               </Col>
          </Row>
        ))}
      </Slider>
      </Container>
 
  );
};

export default SingleCarousel;
