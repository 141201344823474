import React, { useEffect, useState } from "react";
import banner from "../images/individual-internships-banner.webp";
import { Row, Col, Container } from "react-bootstrap";
import Header from "../components/header";
import Footer from "../components/footer";
import whoshouldintern from "../images/who-should-intern-in-france.webp";
import whydointern from "../images/why-do-internship-in-france.webp";
import tripsframe from "../images/trips-frame2.webp";
import Accordionmain from "../components/accordion";
import BlogCarousel from "../components/blogcarousel";
import blogData from "../components/blogData.json";
import formimg from "../images/form-img.png";
import { Link } from "react-router-dom";
import whiteArrow from "../images/arrow-white.svg";
import axios from "axios";
import clientsData from "../components/clientsData.json";
import CarouselCards from "../components/carousel";
import star from "../images/star.svg";
import whydo1 from "../images/what-do-you-gain1.webp";
import whydo2 from "../images/what-do-you-gain2.webp";
import tick from "../images/tick-circle.png";
import howdowehelp from "../images/how-do-we-help.webp";



const Individualinternshipspage = () => {

    const faqItems = [
        {
          title: "Where can anyone contact ALZEA INDIA for a prompt response?",
          value:
            "For prompt response to your queries or concerns, we recommend contacting us directly using the provided email ID or phone number. Our team will be more than happy to assist you and provide you with the information you need in a timely manner.",
        },
        {
          title:
            "Is knowledge of French mandatory for internships or work permits in France or French-speaking countries like Mauritius?",
          value:
            "While not always a strict requirement, knowing French can be beneficial. We offer a complimentary in-house French language course to support interns, enhancing their language skills and competitiveness. For work permits in France, basic French knowledge is necessary, and some properties may require certification.",
        },
      ];
    
      const [BlogsForCarousel, setBlogsForCarousel] = useState([]);
      useEffect(() => {
        if (blogData) {
          const importImages = async () => {
            const images = await Promise.all(
              blogData.map(async (data) => {
                const imageModule = await import(`../images/${data.image}`);
                return imageModule.default;
              })
            );
            setBlogsForCarousel(
              blogData.map((data, index) => ({
                id: data.id,
                title: data.title,
                image: images[index],
                date: data.date,
                type: data.type,
                style: data.styles,
              }))
            );
          };
          importImages();
        }
      }, []);
    
      const [formData, setFormData] = useState({
        name: "",
        email: "",
        mobileNumber: "",
        college: "",
        leavedate: "",
        whyfrance: "",
        experience: "",
        query: ""
      });
    
      const handleChange = (event) => {
        setFormData({
          ...formData,
          [event.target.name]: event.target.value,
        });
      };
      const handleSubmit = (event) => {
        event.preventDefault();
        axios
          .post("http://localhost:3001/franceInternshipformfillup", formData) // Pass formData directly
          .then((res) => {
            console.log("registered successfully");
            setFormData({
              name: "",
              email: "",
              mobileNumber: "",
              college: "",
              leavedate: "",
              whyfrance: "",
              experience: "",
              query: ""
            });
          })
          .catch((err) => {
            console.log(err);
          });
      };

      const [clientcardItems, setclientscardItems] = useState([]);

      useEffect(() => {
           if(clientsData){
              const importclientImages = async () => {
                   const clientimages = await Promise.all( 
                      clientsData.map( async (items) => {
                             const imageModule = await import(`../images/${items.image}`);
                             return imageModule.default;
                      })
                   );
    
                   setclientscardItems(
                      clientsData.map( (items, index) =>({
                         id: items.id,
                         name: items.name,
                         image: clientimages[index],
                         info: items.info
                      }))
                   )
              }
              importclientImages();
           }
      },[])

  return (
    <div className="sec-start">
      <Header />
      <Container>
        <Row>
          <Col md="6">
            <h1>Mauritius</h1>
            <p>
            This captivating island, nestled in the Indian Ocean, offers a unique blend of cultural influences and abundant career opportunities in the hotel management and culinary arts sectors, thanks to its growing luxurious hospitality industry. Mauritius' French-speaking atmosphere adds an extra charm, providing opportunities to enhance your language skills while advancing in the European hospitality industry. Our internship program, lasting 6 months to 1 year, offers a seamless administrative process, simplifying your path to an extraordinary opportunity. Experience the vibrant cultural mosaic of Mauritius as you embark on this enriching internship journey with ALZEA INDIA.             </p>
          </Col>
          <Col md="6">
            <img
              src={banner}
              style={{ width: "100%" }}
              alt="Individual Banner"
            />
          </Col>
        </Row>
        <Row className="pt-5 pb-5">
          <Col md="6">
            <img
              src={whoshouldintern}
              style={{ width: "100%" }}
              alt="Who Should Intern In France"
            />
          </Col>
          <Col md="6">
            <h2>Who should do an Internship in Mauritius?</h2>
            <p className="why-go-through-us-p">
              <span className="number">1</span>
              <span>
              Students recently completed or currently pursuing a degree or diploma course in hotel management studies or bakery and culinary arts, eager to gain practical experience.              </span>
            </p>
            <p className="why-go-through-us-p">
              <span className="number">2</span>
              <span>
              Young adults aged 18-31, ready to explore and immerse themselves in Mauritius's dynamic hospitality sector.
              </span>
            </p>
            <p className="why-go-through-us-p">
              <span className="number">3</span>
              <span>
              Students aspiring to explore further work opportunities abroad, and the ones wishing to launch their own ventures or curious learners of the international cuisine and hospitality industry.              </span>
            </p>
          </Col>
        </Row>
        <Row className="pb-5">
            <Col md="6">
            <h2>Why do an internship in Mauritius?</h2>
            <p className="why-go-through-us-p">
              <span className="number">1</span>
              <span>
              <b>Enhanced Career Opportunities:</b> Open doors to enhanced job prospects globally, particularly in French-speaking nations, while building valuable networking connections.              </span>
            </p>
            <p className="why-go-through-us-p">
              <span className="number">2</span>
              <span>
              <b>Luxurious Hospitality Sector:</b> Dive into Mauritius' thriving hospitality industry, renowned for its opulent resorts, exquisite dining establishments, and unparalleled service standards.              </span>
            </p>
            <p className="why-go-through-us-p">
              <span className="number">3</span>
              <span>
              <b>Language and Cultural Enrichment:</b> Master a new language and delve into diverse cultural experiences, fostering personal growth and expanding your cross-cultural skills repertoire.              </span>
            </p>
            </Col>
            <Col md="6">
                <img src={whydointern} style={{width:"100%"}} alt="Why do internship in france" />
            </Col>
        </Row>
        <div className="pt-5 pb-5" style={{ backgroundColor: "#f5f4f4" }}>
          <h2 className="text-center pt-3 pb-3">
            Snapshots Of Our Trips In Mauritius
          </h2>
          <img src={tripsframe} style={{ width: "100%" }} alt="Trips Frame" />
        </div>
        <Row>
            <h2 className="text-center pt-5 pb-5">How do we Help You? </h2>
            <p style={{maxWidth:"1024px", textAlign:"center", margin:"0 auto"}}>
              At ALZEA INDIA, we provide comprehensive support throughout your journey, treating you not as a mere client but as a cherished member of our family. We are dedicated to ensuring that your internship experience in France becomes a highlight of your academic and professional career, thanks to our french language training and complimentary consultation sessions.
            </p>
            <ul style={{maxWidth:"1024px", margin:"0 auto"}}>
               <li>Recent Graduate (+6 months experience)
                Minimum 3-6 months of prior experience in the respected field is required. 
                For eg. If you're  a culinary candidate he should have 3-6 months experience in culinary before. The same applies for other departments.</li>
                <li>Hotel Management/Culinary Arts Degree or Diploma</li>
                <li>Strong Communication Skills</li>
            </ul>

            <Col md="6">
                <img src={howdowehelp} style={{width:"100%"}} alt="how do we help you?" />
            </Col>
            <Col md="6" style={{margin:"auto 0"}}>
            <p className="why-go-through-us-p">
              <span className="number">1</span>
              <span>
              <h4>Meals</h4> Duty meals are typically provided, but may vary depending on the establishment's policy.
              </span>
            </p>
            <p className="why-go-through-us-p">
              <span className="number">2</span>
              <span>
                <h4>Accommodation</h4>
                Accommodations are arranged in partner hotels, but amenities may vary based on availability and establishment policies.
              </span>
            </p>
            <p className="why-go-through-us-p">
              <span className="number">3</span>
              <span>
               <h4>Visa</h4>
               Complete assistance with visa processing is included in our services.</span>
            </p>
            <p className="why-go-through-us-p">
              <span className="number">4</span>
              <span>
               <h4>Insurance</h4>
               While insurance is not included, we offer guidance and assistance in securing appropriate coverage.</span>
            </p>
            <p className="why-go-through-us-p">
              <span className="number">5</span>
              <span>
               <h4>Travel</h4>
               Travel arrangements, including flight and train tickets, are the responsibility of the student. However, our dedicated travel team is available to provide support and guidance throughout the process.
              </span>
            </p>
            </Col>
        </Row>
        <Row className="pt-5 pb-5">
          <h2 className="text-center pt-3 pb-3">What Do You Gain?</h2>
          <Col md="6">
            <img
              src={whydo1}
              style={{ width: "100%" }}
              alt="What does it offer part 1"
            />
            <div className="whydoes-content">
              <h3>Materialistic</h3>
                <div className="whydoes-p">
                  <img src={tick} className="tick-img"  alt="tick" />
                  <span>Attractive Stipend.</span>
                </div>
                <div className="whydoes-p">
                  <img src={tick} className="tick-img"  alt="tick" />
                  <span>Experience Certificate and Recommendation letters.</span>
                </div>
                <div className="whydoes-p">
                  <img src={tick} className="tick-img"  alt="tick" />
                  <span>Hands-on training under seasoned chefs at prestigious properties and resorts.</span>
                </div>
            </div>
          </Col>
          <Col md="6" className="pt-sm-5 pt-lg-0">
            <img
              src={whydo2}
              style={{ width: "100%" }}
              alt="What does it offer part 2"
            />
            <div className="whydoes-content">
              <h3>Non-Materialistic</h3>
                <div className="whydoes-p">
                  <img src={tick} className="tick-img" alt="tick" />
                  <span>Networking opportunities with interns from around the world.</span>
                </div>
                <div className="whydoes-p">
                  <img src={tick} className="tick-img" alt="tick" />
                  <span>Cultural immersion and exposure.</span>
                </div>
                <div className="whydoes-p">
                  <img src={tick} className="tick-img" alt="tick" />
                  <span>Personal and professional development opportunities.</span>
                </div>
            </div>
          </Col>
        </Row>
        <Row className="pt-5 pb-5">
          <h2 className="text-center">Why Choose Us Over Exciting Offers?</h2>
          <div className="star-content">
            <img src={star} alt="star" />
            <span>
            We offer comprehensive support, including complimentary services such as French language training and consultation, tailored to address cultural and language barriers.            </span>
          </div>
          <div className="star-content">
            <img src={star} alt="star" />
            <span>
            Priority consideration for future internships and work permit placements is given to those who have completed internships through us.            </span>
          </div>
          <div className="star-content">
            <img src={star} alt="star" />
            <span>
            Our commitment goes beyond mere assistance; we strive to create a welcoming environment where each student feels at home, ensuring dedicated support throughout their journey.            </span>
          </div>
          <div className="star-content">
            <img src={star} alt="star" />
            <span>
            We personalise your program to meet your specific needs, whether it involves combining programs or selecting dream hotels.
            </span>
          </div>
          <button type="Submit" style={{ margin: "0 auto", maxWidth: "150px" }}>
          <Link to="/why-alzea">
            Know More <img src={whiteArrow} alt="Arrow" />
            </Link>
          </button>
        </Row>
        <div className="mt-5 carousel">
        <div className="carousel-content ">
          <div>
            <h2>What Our Clients Say About Us</h2>
            <p>
              Lorem ipsum dolor sit amet consectetur. Aliquet tempus mollis dis
              adipiscing ipsum egestas purus quam etiam.
            </p>
          </div>
        </div>
        <CarouselCards data={clientcardItems} />
      </div>
        <Row className="faq-study-trips mt-5 mb-5">
          <Col md="12" lg="4">
            <h2>Frequently Asked Questions</h2>
            <p>
              Lorem ipsum dolor sit amet consectetur. In ut nec feugiat vitae
              vitae iaculis risus ugiat vitae vitae iaculis risus
            </p>
            <Link to="/faqs" className="view-btn">
              View All
              <img className="arrowIcon" alt="" src={whiteArrow}></img>
            </Link>
          </Col>
          <Col md="12" lg="8">
            <Accordionmain Items={faqItems} />
          </Col>
        </Row>
        <div className="mt-5 mb-5">
          <h2>Check out These Blogs</h2>
          <p>
          Read about the francophone countries, the trends in the hospitality industry, India’s relation with the french language, facts about the world abroad, the success and challenges abroad, ongoing relatable debates and much more.
          </p>
          <BlogCarousel data={BlogsForCarousel} />
        </div>
        <Row className="pt-5 pb-5">
          <Col sm="12" md="12" lg="6">
            <h2>Let’s Connect</h2>
            <img src={formimg} style={{width:"100%"}}  className="form-img" alt="Fill the form" />
          </Col>
          <Col sm="12" md="12" lg="6">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="name">
                    Name:{" "}
                    <span style={{ color: "red", fontSize: "20px" }}>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="John Doe"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="email">
                    Email:{" "}
                    <span style={{ color: "red", fontSize: "20px" }}>*</span>
                  </label>
                  <input
                    type="tel"
                    placeholder="johndoe@abc.com"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="mobileNumber">
                    Mobile Number:{" "}
                    <span style={{ color: "red", fontSize: "20px" }}>*</span>
                  </label>
                  <input
                    type="tel"
                    placeholder="829-983-1929"
                    id="mobileNumber"
                    name="mobileNumber"
                    value={formData.mobileNumber}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="college">
                    Your College:{" "}
                    <span style={{ color: "red", fontSize: "20px" }}>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="New Horizon College"
                    id="college"
                    name="college"
                    value={formData.college}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <label htmlFor="college">
                When Do You Wish To Leave?:{" "}
                <span style={{ color: "red", fontSize: "20px" }}>*</span>
              </label>
              <input
                type="date"
                placeholder="Select Date"
                id="leavedate"
                name="leavedate"
                value={formData.leavedate}
                onChange={handleChange}
              />
              <label htmlFor="query">
                Why France?:{" "}
                <span style={{ color: "red", fontSize: "20px" }}>*</span>
              </label>
              <textarea
                rows="4"
                cols="50"
                placeholder="Leave a message"
                id="whyfrance"
                name="whyfrance"
                value={formData.whyfrance}
                onChange={handleChange}
              ></textarea>
             
              <label htmlFor="Do You Have Prior Experience?">
              Do You Have Prior Experience?:{" "}
                <span style={{ color: "red", fontSize: "20px" }}>*</span>
              </label>
              <textarea
                rows="4"
                cols="50"
                placeholder="Leave a message"
                id="experience"
                name="experience"
                value={formData.experience}
                onChange={handleChange}
              ></textarea>

              <label htmlFor="query">
                Enter Your Query: <span style={{ color: "red", fontSize: "20px" }}>*</span>
              </label>
              <textarea
                rows="4"
                cols="50"
                placeholder="Leave a message"
                id="query"
                name="query"
                value={formData.query}
                onChange={handleChange}
              ></textarea>

              <button type="submit">Submit</button>
            </form>
          </Col>
        </Row>
      </Container>

      <Footer />
    </div>
  );
};

export default Individualinternshipspage;
