import React, { useState } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import banner from "../images/about-us-banner.webp";
import { Col, Container, Row } from "react-bootstrap";
import brands from "../images/brands.png";
import introaboutimg from "../images/intro-about-us.webp";
import team from "../images/team-member-about-us.webp";
import lknd from "../images/lkdn-about.svg";
import mission from "../images/about-us-mission-values.webp";
import Eiffel from "../images/eiffel.png";
import { Link } from "react-router-dom";
import Teamcarousel from "../components/teamcarousel";
import star from "../images/star.svg";
import sliderimg from "../images/sliderimg.svg";
import Slider from "@material-ui/core/Slider";
import priyankaImg from "../images/Priyanka.webp";
import bhavayImg from "../images/Bhavay.webp";
import aditiImg from "../images/Aditi.webp";
import rashiImg from "../images/Rashi.webp";
import jatinImg from "../images/Jatin.webp";
import rightIcon from "../images/right-icon.svg";
import leftIcon from "../images/left-icon.svg";

const About = () => {
  const memberinfo = [
    {
      name: "PRIYANKA AGARWAL",
      role: "FOUNDER",
      link: "https://www.linkedin.com/in/priyanka-jaju-agrawal/",
    },
    {
      name: "RASHI GULATI",
      role: "BUSINESS DEVELOPMENT MANAGER",
      link: "https://www.linkedin.com/in/rashi-gulati-bb77541a6/",
    },
    {
      name: "BHAVAY SHARMA",
      role: "FRENCH LANGUAGE EXPERT",
      link: "https://www.linkedin.com/in/ibhavay/",
    },
    {
      name: "ADITI PAREKH",
      role: "HR",
      link: "https://www.linkedin.com/in/aditi-parekh-576992218/",
    },
    {
      name: "JATIN CHAUDHARI",
      role: "TECH and DEV",
      link: "https://www.linkedin.com/in/jatin-chaudhari-07798b199/",
    },
  ];
  const teamData = [
    {
      id: 1,
      name: "PRIYANKA AGARWAL",
      text:
        "“Lorem ipsum dolor sit amet consectetur. Aliquet tempus mollis dis adipiscing ipsum egestas purus quam etiam. In mattis",
      image: priyankaImg,
      role: "FOUNDER",
      title: "FOUNDER",
    },
    {
      id: 2,
      name: "RASHI GULATI",
      text:
        "“Lorem ipsum dolor sit amet consectetur. Aliquet tempus mollis dis adipiscing ipsum egestas purus quam etiam. In mattis",
      image: rashiImg,
      role: "MANAGER",
      title: "BUSINESS DEVELOPMENT MANAGER",
    },
    {
      id: 3,
      name: "BHAVAY SHARMA",
      text:
        "“Lorem ipsum dolor sit amet consectetur. Aliquet tempus mollis dis adipiscing ipsum egestas purus quam etiam. In mattis",
      image: bhavayImg,
      role: "LANGUAGE EXPERT",
      title: "FRENCH LANGUAGE EXPERT",
    },
    {
      id: 4,
      name: "ADITI PAREKH",
      text:
        "“Lorem ipsum dolor sit amet consectetur. Aliquet tempus mollis dis adipiscing ipsum egestas purus quam etiam. In mattis",
      image: aditiImg,
      role: "HR",
      title: "HR AND ACCOUNTS",
    },
    {
      id: 5,
      name: "JATIN CHAUDHARI",
      text:
        "“Lorem ipsum dolor sit amet consectetur. Aliquet tempus mollis dis adipiscing ipsum egestas purus quam etiam. In mattis",
      image: jatinImg,
      role: "DEVELOPER",
      title: "TECH",
    },
  ];

  const corevalues = [
    {
      value: "Trust",
      text:
        "Honesty, transparency and accountability throughout the process makes us truystworthy.",
    },
    {
      value: "Passion",
      text:
        "Our team at ALZEA INDIA is fueled by willingness to go above and beyond the call of duty, reflecting our passion for our work.",
    },
    {
      value: "Commitment to Students",
      text:
        "We take a limited number of students and train and support them throughout, thus sticking true to our commitment.",
    },
    {
      value: "Innovation",
      text:
        "We closely analyze the hardships our students meet, and develop solutions in the form of complimentary programs, highlighting our dedication to an innovative approach.",
    },
    {
      value: "Adaptability",
      text:
        "Embracing change and staying adaptable in the dynamic educational landscape is a core value at ALZEA INDIA. We recognize the importance of continually evolving our methods and offerings to meet the ever-changing needs of our students, ensuring that we remain at the forefront of providing effective and relevant educational solutions.",
    },
  ];

  const [selectedYear, setSelectedYear] = useState(2011);

  const handleChangeYear = (event, value) => {
    setSelectedYear(value * 2 + 2011);
    // You can implement logic here to change content based on selected year
  };

  // Example JSON data mapped with years
  const contentData = {
    2011: {
      image: sliderimg,
      title: "Title for 2011",
      text:
        "Expanded our horizons by initiating internship programs for Indian hospitality students in France, opening unparalleled learning opportunities in renowned establishments abroad.",
    },
    2013: {
      image: sliderimg,
      title: "Title for 2013",
      text:
        "Responding to the growing demand for linguistic proficiency, we introduced complimentary French language classes for internship students. Additionally, we launched separate language courses tailored for language enthusiasts keen on exploring the intricacies of French.",
    },
    2015: {
      image: sliderimg,
      title: "Title for 2015",
      text:
        "Broadened our scope by adding Mauritius to our portfolio, providing Indian hospitality students with the chance to gain invaluable experience in a vibrant and diverse setting. This milestone was accompanied by successfully managing our first cohort of 200 students, marking a significant achievement in our journey.",
    },
    2017: {
      image: sliderimg,
      title: "Title for 2017",
      text:
        "Further diversified our offerings by including the USA and Thailand in our portfolio, facilitating internships for Indian hospitality students in these dynamic destinations. This period also witnessed a remarkable milestone as we surpassed the mark of 500 students served, a testament to our unwavering commitment to student success.",
    },
    2019: {
      image: sliderimg,
      title: "Title for 2019",
      text:
        "Amidst the challenges posed by the COVID-19 pandemic, we demonstrated resilience and responsibility by safely repatriating students to India, ensuring their well-being, and offering refunds to eligible students—a testament to our commitment to student welfare and satisfaction.",
    },
    2021: {
      image: sliderimg,
      title: "Title for 2021",
      text:
        "Embraced digital innovation with the launch of Fluentzea online portal, providing convenient access to French language classes for hospitality students and enthusiasts worldwide, thereby breaking geographical barriers to language learning. Expanded our services beyond conventional boundaries by introducing complementary health coaching and the WildFit program into our internship curriculum, prioritizing holistic student development and well-being.",
    },
    2023: {
      image: sliderimg,
      title: "Title for 2023",
      text:
        "Pioneered the provision of Netherlands work permit services for hospitality students, further enhancing our commitment to facilitating international career opportunities and experiences. Embarked on a transformative journey of rebranding, symbolized by a refreshed logo and the establishment of two distinct entities—Destiny Calling, our sister specializing in internships and work permits in non-Francophone countries, and ALZEA INDIA, our flagship brand dedicated to all things Francophone. This evolution also saw the introduction of study programs and trips in Francophone countries, enriching the educational landscape for our students.",
    },
  };
  const marks = [
    { value: 0, label: "2011" },
    { value: 1, label: "2013" },
    { value: 2, label: "2015" },
    { value: 3, label: "2017" },
    { value: 4, label: "2019" },
    { value: 5, label: "2021" },
    { value: 6, label: "2023" },
  ];

  return (
    <div className="sec-start">
      <Header />
      <h1 className="text-center pt-4 aboutHead">About Our Brand</h1>
      <p style={{maxWidth:"1200px", margin:"0 auto", padding:"10px"}} className="text-center">Stemming from where Alzea France, ALZEA INDIA was born. Started as just a facilitator for Indian and French nationals wishing to participate in a cultural exchange and looking to build an international career through Internships, ALZEA INDIA now is proud to be a hub for everything francophone. From widening our horizon and growing independent, we are now the only consultancy to be completely autonomous in most of the procedures. Facilitating a smooth, hassle free experience is what sits at the core of our values and aiming to instill the love we hold for francophonie, our only vision.</p>
      <Container className="pb-5 pt-3">
        <img src={banner} style={{ width: "100%" }} alt="banner" />
      </Container>
      <img src={brands} style={{ width: "100%" }} alt="Brands" />
      <Container>
        <Row className="pt-5 pb-5">
          <Col
            md="6"
            style={{
              backgroundColor: "#F5F4F4",
              padding: "20px 20px",
              overflow: "auto",
              maxHeight: "450px",
            }}
          >
            <h2>Introduction </h2>
            <p>
              ALZEA INDIA provides services in India and francophone countries,
              offering a range of opportunities, including internships primarily
              in the hospitality sector, French language courses. These courses
              encompass online pre-recorded sessions and live classes with our
              trained, professional, certified French language trainers. We
              specialize in DELF and DALF courses, as well as TEF courses.
              Additionally, we provide worksheets and guides covering specific
              topics in the French language. All our courses are designed and
              our teachers trained to focus on teaching the language as a
              foreign language, utilizing interactive activities, games,
              modules, appealing presentations, and culturally relevant
              examples.
            </p>
            <p>
              In addition to language services, we also facilitate study
              programs in francophone countries for graduates and
              undergraduates, as well as study trips catering to students of all
              age groups. These trips provide an opportunity to explore
              francophone countries or territories, and vice versa, with
              francophones eager to explore India.
            </p>
            <p>
              While this encapsulates our current services, we are continually
              expanding and growing. We are always on the lookout for new
              opportunities in the dynamic world of the francophone community.
            </p>
          </Col>
          <Col md="6" style={{ padding: 0 }}>
            <img
              src={introaboutimg}
              style={{ width: "100%" }}
              alt="Introduction"
            />
          </Col>
        </Row>
        <div>
          <h2>Introduction to Key Team Members </h2>
          <p>
            Our founder, Priyanka Agarwal, while interning in Toulouse with
            Alzea France, made the decision to start ALZEA INDIA, and the
            website you scroll through today is her success story. A snippet of
            her with the pillars she turned thai dream into reality with.
          </p>
          <img src={team} alt="Team Member" style={{ width: "100%" }} />
          <div className="team-div pt-5 pb-5">
            {memberinfo.map((member) => {
              return (
                <div className="team-members">
                  <a href={member.link}  rel="noreferrer" target="_blank">
                    <div className="member-info">
                      <h4>{member.name}</h4>
                      <p>{member.role}</p>
                    </div>
                    <img src={lknd} className="lkdn" alt="Linkedin" />
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </Container>
      <div className="history">
        <Container>
          <h2>Our History</h2>
          <p>
            As previously stated, ALZEA INDIA stems from the idea of our founder
            to facilitate experiences that were missing for indian students,
            seamless internships in francophone countries, culturally immersive
            exchanges and study trips, easier access to study programs in france
            and other french speaking nations, and french language training that
            is not just focused on giving mere translations and learning the
            language but understanding it’s cultural roots and the places its
            expressions, sayings and contexts stem from.
          </p>
          <p>
            To dive a bit deeper in the past, Priyanka Agrawal, our founder and
            a passionate individual, discovered ALZEA through a pinned
            advertisement during her French courses at Alliance Française.
            Excited by the opportunity, she applied for a Business Development
            and Marketing Internship. Impressed by her MBA degree, knowledge in
            tourism, and strong French language skills, Christin, the Head of
            ALZEA France, granted her the opportunity.
          </p>
          <p>
            Initially, Priyanka&#39;s role involved mentoring French interns in
            India, providing them with an authentic cultural experience. As she
            immersed herself in this role, the idea of offering internships to
            Indian students in France took hold. It was during her tours with
            the French interns that she often received inquiries from Indians,
            for similar opportunities in France. This planted the seeds for
            ALZEA INDIA.
          </p>
          <p>
            In 2011, ALZEA INDIA kickstarted its internship service in France by
            placing 10 IHM Indian Hotel Management students in the culinary
            capital.. Since then, the organization has grown exponentially,
            sending over 1,200 students to fulfill their dreams of interning in
            France.
          </p>
          <p>
            After achieving success in facilitating internships, ALZEA INDIA
            decided to grow independ and expand its wing further to all
            francophone countries, to offer not just internships but study
            programs, trips, cultural exchange, language courses, etc. It has
            now become a bridge between ambitious Indian students and
            opportunities in all French speaking islands, nations and
            territories.
          </p>
          <p>
            The ALZEA INDIA story is one of passion, determination, and the
            belief in empowering students through international francophonie
            opportunities. With each success story and transformative
            experience, ALZEA INDIA continues to pave the way for young talents
            to pursue their dreams across borders.
          </p>
        </Container>
      </div>
      <Container>
        <Row className="pt-5 pb-5">
          <Col md="6" style={{ margin: "auto 0" }}>
            <h2>Mission & Values</h2>
            <p>
              Having empowered over 1200 students to secure promising careers
              and evolve into their best selves, our relentless pursuit is now
              directed towards staying abreast of industry dynamics &amp;
              enhancing our services. Our commitment is unwavering — to
              ethically serve our students, driven solely by goodwill and their
              best interests.
            </p>
            <p>
              We envision Alzea India as the premier platform connecting
              students to transformative internships, enriching study programs,
              culturally immersive study trips, and unparalleled language
              services extending beyond the classroom into real-life
              applications.
            </p>
            <p>
              As the exclusive autonomous consultancy in our field and the sole
              francophone mentor in India offering such diverse services, we aim
              to instill the love for francophonie in students and setting them
              up to experience transformative exposure and emerge as confident
              individuals. We remain steadfastly accountable, preserving our
              integrity, and upholding our core values.
            </p>
          </Col>
          <Col md="6">
            <img
              src={mission}
              style={{ width: "100%" }}
              alt="Mission and values"
            />
          </Col>
        </Row>
        <div>
          <h2 className="text-center">
            Core values that guide the organization.
          </h2>
          <div className="values-container">
            {corevalues.map((item) => {
              return (
                <div className="values-div">
                  <img src={star} alt="star" />
                  <h4>{item.value}</h4>
                  <p>{item.text}</p>
                </div>
              );
            })}
          </div>
        </div>
        <div  style={{ width: '80%', margin: 'auto' }}>
        <Slider
            min={0}
            max={6}
            step={1}
            value={(selectedYear - 2011) / 2}
            onChange={handleChangeYear}
            marks={marks}
            aria-labelledby="slider"
            className="slider"
            style={{ overflowX: 'auto' }}
          />
          <div>
            <Row>
              <Col md={6}>
                <img
                  src={contentData[selectedYear]?.image}
                  alt={`Image for ${selectedYear}`}
                  style={{ width: "100%" }}
                />
              </Col>
              <Col md={6} style={{ margin: "auto 0" }} className="pt-4 pt-md-0">
                <h2>{contentData[selectedYear]?.title}</h2>
                <p>{contentData[selectedYear]?.text}</p>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
      <div className="Team-sec">
        <h2>Take A Look At Our Team</h2>
        <p style={{ maxWidth: "1100px" }}>
          Meet the heart and soul of ALZEA INDIA - our dedicated team of
          individuals who embody passion, expertise, and a shared commitment to
          excellence. They are the driving force behind the success and
          reputation we enjoy today. Each team member at ALZEA INDIA brings a
          unique set of skills and experiences, united by a common goal: to
          empower students, inspire growth, and make a positive impact on
          education. Explore the faces behind our success and discover the
          passion that fuels everything we do at ALZEA INDIA.
        </p>
        <Teamcarousel data={teamData} />
      </div>
      <Container>
        <Row
          className="mt-5 mb-5"
          style={{
            background: "#1A2B60",
            borderRadius: "20px",
            margin: "0 auto",
          }}
        >
          <Col md="7" className="eiffel-content">
            <h2>DO YOU WISH TO KNOW MORE ABOUT US?</h2>
            <p>CONTACT US NOW</p>
            <Link to="/contact" className="eiffel-button-contact py-3">
              Contact us
            </Link>
          </Col>
          <Col md="5">
            <img src={Eiffel} style={{ width: "100%" }} alt="Eiffel Tower" />
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default About;
