import React from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import "../styles/Home-page.css";
import { useState, useEffect, useRef } from "react";
import homedesktop from "../images/home-banner.webp";
import homemobile from "../images/home-banner-mobile.webp";
import brands from "../images/brands.png";
import { Row, Col } from "react-bootstrap";
import learnmore from "../images/learn-more.svg";
import studytripsimg from "../images/study-trips.png";
import internshipsimg from "../images/internships.png";
import studyprogamsimg from "../images/study-programs.png";
import AboutHomeimg from "../images/about-home-page.png";
import pattern from "../images/pattern.svg";
import pattern2 from "../images/pattern2.svg";
import ourMissionDesktopimg from "../images/ourmission-desktop.png";
import ourMissionMobileimg from "../images/our-mission-mobile.png";
import CarouselCards from "../components/carousel";
import Accordionmain from "../components/accordion";
import arrow from "../images/learn-more.svg";
import { Link } from "react-router-dom";
import formimg from "../images/form-img.png";
import thumbnail from "../images/Thumbnail.png";
import video from "../files/testimonials.mp4";
import BlogCarousel from "../components/blogcarousel";
import blogData from "../components/blogData.json";
import axios from "axios";
import clientsData from "../components/clientsData.json";
import fluentzeaimg from "../images/fluentzeaimg.webp";


const Home = () => {
  const [bannerimg, setImgSrc] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [BlogsForCarousel, setBlogsForCarousel] = useState([]);

  useEffect(() => {
    if (blogData) {
      const importImages = async () => {
        const images = await Promise.all(
          blogData.map(async (data) => {
            const imageModule = await import(`../images/${data.image}`);
            return imageModule.default;
          })
        );
        setBlogsForCarousel(
          blogData.map((data, index) => ({
            id: data.id,
            title: data.title,
            image: images[index],
            date: data.date,
            type: data.type,
            style: data.styles
          }))
        );
      };
      importImages();
    }
  }, []);

  const [clientcardItems, setclientscardItems] = useState([]);

  useEffect(() => {
       if(clientsData){
          const importclientImages = async () => {
               const clientimages = await Promise.all( 
                  clientsData.map( async (items) => {
                         const imageModule = await import(`../images/${items.image}`);
                         return imageModule.default;
                  })
               );

               setclientscardItems(
                  clientsData.map( (items, index) =>({
                     id: items.id,
                     name: items.name,
                     image: clientimages[index],
                     info: items.info
                  }))
               )
          }
          importclientImages();
       }
  },[])

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setWindowWidth(screenWidth);

      if (screenWidth > 768) {
        setImgSrc(homedesktop);
      } else {
        setImgSrc(homemobile);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const faqItems = [
    {
      title: "Where can anyone contact ALZEA INDIA for a prompt response?",
      value:
        "For prompt response to your queries or concerns, we recommend contacting us directly using the provided email ID or phone number. Our team will be more than happy to assist you and provide you with the information you need in a timely manner.",
    },
    {
      title:
        "Is knowledge of French mandatory for internships or work permits in France or French-speaking countries like Mauritius?",
      value:
        "While not always a strict requirement, knowing French can be beneficial. We offer a complimentary in-house French language course to support interns, enhancing their language skills and competitiveness. For work permits in France, basic French knowledge is necessary, and some properties may require certification.",
    },
  ];


  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    university: "",
    degree: "",
    query: "",
  });

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };
   const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .post("http://localhost:3001/homeformfillup", formData) // Pass formData directly
      .then((res) => {console.log("registered successfully")
      setFormData({
        name: "",
        email: "",
        mobileNumber: "",
        university: "",
        degree: "",
        query: "",
      });
       })
      .catch((err) => {console.log(err)});
  };
  
  // const [controlsVisible, setControlsVisible] = useState(false);
  // const videoRef = useRef(null);
  // const handleVideoClick = () => {
  //   setControlsVisible(true);
  //   const videoElement = videoRef.current;
  //   // Reset currentTime to 0 if video has ended
  //   if (videoElement.ended) {
  //     videoElement.currentTime = 0;
  //   }
  //   // Play the video
  //   if (videoElement) {
  //     videoElement.play().catch((error) => {
  //       console.error("Error playing video:", error);
  //     });
  //   }
  // };

  return (
    <div className="sec-start">
      <Header />
      <div className="container main-container">
        <div className="tags ">
          <div className="tag1">Internships</div>
          <div className="tag2">Experience</div>
          <h1 className="weAreYourHeading">
            EMPOWERING STUDENTS ACROSS THE WORLD
          </h1>
          <div className="tag3">Students</div>
          <div className="tag4">Trips</div>
        </div>
        <img src={bannerimg} alt="Banner Alzea" />
      </div>
      <img src={brands} className="brands" alt="Banner Alzea" />

      <div className="container-fluid">
        <div className="container programs">
          <Row>
            <Col md="12" lg="6">
              <div className="studyProgramsGroup">
                <div className="studyProgramsContent">
                  <div className="programsTitle">Study Programs</div>
                  <div className="programsDescription">
                  Giving you wings to live your dream student life abroad, and adding an ‘international degree’
                  feather to the cap of your ‘career’.
                  </div>
                </div>
                <img className="programsImage" alt="" src={studyprogamsimg} />
                <Link to="/study-programs" className="learnMoreButton">
                  <div className="buttonText">Learn More</div>
                  <img className="arrowIcon" alt="" src={learnmore} />
                </Link>
              </div>
            </Col>
            <Col md="12" lg="6">
              <div className="internshipsGroup">
                <div className="internshipsContent">
                  <div className="programsTitle">Internships</div>
                  <div className="programsDescription">
                  Helping you take your first step towards building an international career.<br/>
                  800+ students placed and counting
                  </div>
                </div>
                <img className="internshipsImage" alt="" src={internshipsimg} />
                <Link to="/internships" className="learnMoreButton">
                  <div className="buttonText">Learn More</div>
                  <img className="arrowIcon" alt="" src={learnmore} />
                </Link>
              </div>
            </Col>
          </Row>
          <div>
            <Row>
              <Col md="12" lg="6">
                <div className="study-trips-div">
                <div className="studyTripsContent">
                  <div className="programsTitle">Study Trips</div>
                  <div className="programsDescription">
                  Offering well curated, planned, cultural and unique experiences for students wishing to explore
                  a new culture and de-stress while socializing and learning. Never visiting as a tourist but a local.
                  </div>
                  <img className="studyTripsImage" alt="" src={studytripsimg} />
                  <Link to="/study-trips" className="learnMoreButton">
                    <div className="buttonText">Learn More</div>
                    <img className="arrowIcon" alt="" src={learnmore} />
                  </Link>
                </div>
                </div>
              </Col>
              <Col md="12" lg="6">
                <div className="fluentzea">
                <div className="fluentzea-heading">Fluentzea</div>
                 <p>Cutting off language barriers with more than 25 countries in the world. Travel, network,
                  socialize, strengthen your resume, by learning French, one of the most spoken foreign
                  languages.</p>
                  <img src={fluentzeaimg} className="fluentzeaimg" alt="fluentzea" />
                <Link to="/fluentzea" className="learnMoreButton">
                  <div className="buttonText">Learn More</div>
                  <img className="arrowIcon" alt="" src={learnmore} />
                </Link>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <Row className="about-us">
          <img src={pattern} className="pattern" alt="Pattern 1" />
          <Col sm="12" md="5" lg="6" className="about-content">
            <h2>About Us</h2>
            <p>
            A hub for everything francophone, ALZEA INDIA is a one stop destination for all students to
            whom the French language and its diverse connections attracts. Be it internships, study
            programs, study trips or just learning the french language; we cater to it all.
            </p>
            <a href="/#" className="learnMoreButton">
              <div className="buttonText">Learn More</div>
              <img className="arrowIcon" alt="Learn More" src={learnmore} />
            </a>
          </Col>
          <Col sm="12" md="7" lg="6" className="aboutimg">
            <img className="AboutImage" alt="" src={AboutHomeimg} />
          </Col>
        </Row>
        <img src={pattern2} className="pattern2" alt="Pattern 2" />
      </div>
      <div className="container mt-5 mb-5">
        <div className="our-mission-container">
          <h2>Our Mission & Values</h2>
          <p>
          After having helped 1200+ students secure their careers and grow in to a better versions of
          themselves, our only aim now is to keep up with the the industry changes, bettering our
          services, focusing on not just customer satisfaction but customer delight, and serve our
          students ethically, with just goodwill at heart; what’s in their best interest.
          </p>
          <img
            src={windowWidth < 600 ? ourMissionMobileimg : ourMissionDesktopimg}
            alt="OurMission"
            style={{width:"100%"}}
          />
        </div>
      </div>
      {/* <div className="testimonails">
      <img src={pattern} className="pattern" alt="Pattern 1" />
        <video
          ref={videoRef}
          controls={controlsVisible}
          className="container"
          onClick={handleVideoClick}
          onEnded={() => {
            setControlsVisible(false);
          }}
          poster={thumbnail}
        >
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <img src={pattern} className="pattern3" alt="Pattern 3" />
      </div> */}
      <div className="container mt-5 carousel">
        <div className="carousel-content ">
          <div>
            <h2>What Our Students Say About Us</h2>
          </div>
        </div>
        <CarouselCards data={clientcardItems} />
      </div>
      <div className="container faq-home mt-5 mb-5">
        <Row>
          <Col md="12" lg="4">
            <h2>Frequently Asked Questions</h2>
            <p>
              Lorem ipsum dolor sit amet consectetur. In ut nec feugiat vitae
              vitae iaculis risus ugiat vitae vitae iaculis risus
            </p>
            <Link to="/faqs" className="view-btn">
              View All
              <img className="arrowIcon" alt="" src={arrow}></img>
            </Link>
          </Col>
          <Col md="12" lg="8">
            <Accordionmain Items={faqItems} />
          </Col>
        </Row>
      </div>  
      <div className="container mt-5 mb-5">
        <h2>Check out These Blogs</h2>
        <p style={{maxWidth:"1200px"}}>
        Read about the francophone countries, the trends in the hospitality industry, India’s relation
        with the french language, facts about the world abroad, the success and challenges abroad,
        ongoing relatable debates and much more.
        </p>
        <BlogCarousel data={BlogsForCarousel} />
      </div>
      <div className="container mt-5 mb-5">
        <Row>
          <Col sm="12" md="12" lg="6">
            <h2>Let’s Connect</h2>
            <img src={formimg} style={{width:"100%"}} className="form-img mb-5" alt="Fill the form" />
          </Col>
          <Col sm="12" md="12" lg="6">
            <form onSubmit={handleSubmit}>
              <label htmlFor="name">Name: <span style={{color:"red",fontSize:"20px"}}>*</span></label>
              <input
                type="text"
                placeholder="John Doe"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />

              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="email">Email: <span style={{color:"red",fontSize:"20px"}}>*</span></label>
                  <input
                    type="tel"
                    placeholder="johndoe@abc.com"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="mobileNumber">Mobile Number: <span style={{color:"red",fontSize:"20px"}}>*</span></label>
                  <input
                    type="tel"
                    placeholder="829-983-1929"
                    id="mobileNumber"
                    name="mobileNumber"
                    value={formData.mobileNumber}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <label htmlFor="university">University: <span style={{color:"red",fontSize:"20px"}}>*</span></label>
              <input
                type="text"
                placeholder="Havard University"
                id="university"
                name="university"
                value={formData.university}
                onChange={handleChange}
              />

              <label htmlFor="degree">Degree/Cultivation: <span style={{color:"red",fontSize:"20px"}}>*</span></label>
              <input
                type="text"
                placeholder="HSC/Bacherlors/Masters"
                id="degree"
                name="degree"
                value={formData.degree}
                onChange={handleChange}
              />

              <label htmlFor="query">Query: <span style={{color:"red",fontSize:"20px"}}>*</span></label>
              <textarea
                rows="4"
                cols="50"
                placeholder="Leave a message"
                id="query"
                name="query"
                value={formData.query}
                onChange={handleChange}
              ></textarea>

              <button type="submit">Submit</button>
            </form>
          </Col>
        </Row>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
