import React from 'react';
import Accordion from 'react-bootstrap/Accordion';

function AlwaysOpenExample({ Items }) {
    return (
        <Accordion defaultActiveKey={['0']} alwaysOpen>
            {Items.map((item, index) => (
                <Accordion.Item key={index} eventKey={index.toString()}>
                    <Accordion.Header>{item.title}</Accordion.Header>
                    <Accordion.Body>{item.value}</Accordion.Body>
                </Accordion.Item>
            ))}
        </Accordion>
    );
}

export default AlwaysOpenExample;
