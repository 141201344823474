import React from "react";
import "../styles/footer.css";
import logof from "../images/logo-footer.png";
import "bootstrap/dist/css/bootstrap.min.css";
import { Row, Col } from "react-bootstrap";
import lkdn from "../images/lkdn-footer.png";
import insta from "../images/insta-footer.png";
import fb from "../images/fb-footer.png";
import twtr from "../images/twtr-footer.png";
import yt from "../images/yt-footer.png";
import google from "../images/google-footer.png";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <footer>
        <div className="container">
          <Row className="main-row">
            <Col xs="12" md="12" lg="4" className="col-logo">
              <img src={logof} alt="logo-footer" />
              <p className="pt-3">
                <p>
                  <b>Your Francophone Mentor</b>
                </p>
                Empowering students worldwide on their francophone journey, from
                mastering the language to exploring countries for study, travel,
                or work.
              </p>
              <div className="sm-icons-footer">
                <a href="https://www.linkedin.com/company/alzea-india/" target="_blank"><img src={lkdn}   alt="LinkedIn" /></a>
                <a href="https://www.instagram.com/alzeaindia/?hl=en" target="_blank"><img src={insta} className="mx-3" alt="Instagram" /></a>
                <a href="https://www.facebook.com/ALZEA.internships/" target="_blank"><img src={fb}  alt="Facebook" /></a>
                <a href="https://twitter.com/alzeaindia?lang=en" target="_blank"><img src={twtr} className="mx-3" alt="Twitter" /></a>
                <a href="https://www.youtube.com/channel/UCeO6Aa3Kv20zFb0OFgT2zbQ" target="_blank"><img src={yt} alt="Youtube" /></a>
                <a href="https://g.co/kgs/79SgR6y" target="_blank"><img src={google} className="mx-3" alt="Google" /></a>
              </div>
            </Col>
            <Col xs="12" md="12" lg="6" className="col-content" >
              <Row>
                <Col xs="12" md="4" lg="4">
                  <ul>
                    <h5>Quick links</h5>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/why-alzea">Why Alzea India</Link>
                    </li>
                    <li>
                      <Link to="/internships">Internships</Link>
                    </li>
                    <li>
                      <Link to="/study-programs">Study programs</Link>
                    </li>
                    <li>
                      <Link to="/study-trips">Study Trips</Link>
                    </li>
                  </ul>
                </Col>
                <Col xs="12" md="4" lg="4">
                  <ul>
                    <h5>Company</h5>
                    <li>
                      <Link to="/about">About</Link>
                    </li>
                    <li>
                      <Link to="/faqs">FAQ</Link>
                    </li>
                    <li>
                      <Link to="/blogs">Blogs</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                  </ul>
                </Col>
                <Col xs="12" md="4" lg="4">
                  <ul>
                    <h5>Stay in touch</h5>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="mailto:internships@alzeaindia.com"
                      >
                        internships@alzeaindia.com
                      </a>
                    </li>
                    <li>
                      <a href="tel:+91 95611 01102">+91 95611 01102</a>
                    </li>
                    <li>Mumbai, India</li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </footer>
      <div className="copyright">© All Rights Reserved</div>
    </>
  );
}

export default Footer;
