import React from 'react';
import Slider from 'react-slick';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ArrowForward from '@mui/icons-material/ArrowForward';
import { useState } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Button, Col, Row } from 'react-bootstrap';



const CustomNextArrow = ({ onClick }) => {
  const [clicked, setClicked] = useState(false);

  const handleButtonClick = () => {
    setClicked(!clicked);
    onClick();
  };

  return (
    <Button
      className={`custom-arrow single-next-arrow custom-next-arrow${clicked ? ' clicked' : ''}`}
      onClick={handleButtonClick}
    >
      <ArrowForward />
    </Button>
  );
};

const CustomPrevArrow = ({ onClick }) => {
  const [clicked, setClicked] = useState(false);

  const handleButtonClick = () => {
    setClicked(!clicked);
    onClick();
  };

  return (
    <Button
      className={`custom-arrow  single-prev-arrow custom-prev-arrow${clicked ? ' clicked' : ''}`}
      onClick={handleButtonClick}
    >
      <ArrowBack />
    </Button>
  );
};

const SingleCarousel = (props) => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    responsive: [
      {
        breakpoint: 767, // For tablets and smaller devices
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false
        },
      }
    ],
  };
  
  const Data = props.data;
  

  return (
    
      <Slider {...settings}> 
        {Data.map((item) => (
          <Row key={item.id} className='tab'>
               <Col md="6">
                <img src={item.image} style={{width:"100%"}} alt={item.studentname} />
               </Col>
               <Col md="6" style={{margin:"auto 0"}}>
                <h4>{item.studentname}</h4>
                <span>{item.course} | {item.score}</span>
                <p>{item.content}</p>
               </Col>
          </Row>
        ))}
      </Slider>
 
  );
};

export default SingleCarousel;
