import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { useEffect } from 'react'; // Import useEffect hook
import Home from './pages/homepage';
import AboutUs from './pages/about';
import ContactUs from './pages/contact';
import Blogs from './pages/blogs';
import Internships from './pages/internships';
import Faqs from './pages/faq';
import IndividualBlogPage from './pages/individualblog';
import WhyAlzea from "./pages/whyAlzea";
import Studyprograms from './pages/studyprograms';
import Fluentzea from './pages/fluentzea';
import Studytrips from './pages/studytrips';
import './styles/App.css';
import Francestudytrips from './pages/francestudytrips';
import Franceinternships from './pages/franceinternships';
import Careers from './pages/careers';
import Mauritiusinternships from "./pages/mauritiusinternships";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blog/:id" element={<IndividualBlogPage />} />
          <Route path="/internships" element={<Internships />} />
          <Route path='/faqs' element={<Faqs />} />
          <Route path='/fluentzea' element={<Fluentzea />} />
          <Route path='/study-programs' element={<Studyprograms/>} />
          <Route path='/why-alzea' element={<WhyAlzea/>} />
          <Route path='/study-trips' element={<Studytrips/>} />
          <Route path='/study-trips-in-france' element={<Francestudytrips/>} />
          <Route path='/internships-in-france' element={<Franceinternships />} />
          <Route path='/internships-in-mauritius' element={<Mauritiusinternships />} />
          <Route path='/careers' element={<Careers />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
