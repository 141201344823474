import React from 'react';
import Slider from 'react-slick';
import { Button, Card, CardContent, Typography } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ArrowForward from '@mui/icons-material/ArrowForward';
import { useState } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import cardbg from '../images/card-bg.png';
import parse from 'html-react-parser';
import bluepattern from "../images/blue-pattern.svg";

const CustomNextArrow = ({ onClick }) => {
  const [clicked, setClicked] = useState(false);

  const handleButtonClick = () => {
    setClicked(!clicked);
    onClick();
  };

  return (
    <Button
      className={`custom-arrow custom-next-arrow${clicked ? ' clicked' : ''}`}
      onClick={handleButtonClick}
    >
      <ArrowForward />
    </Button>
  );
};

const CustomPrevArrow = ({ onClick }) => {
  const [clicked, setClicked] = useState(false);

  const handleButtonClick = () => {
    setClicked(!clicked);
    onClick();
  };

  return (
    <Button
      className={`custom-arrow custom-prev-arrow${clicked ? ' clicked' : ''}`}
      onClick={handleButtonClick}
    >
      <ArrowBack />
    </Button>
  );
};

const CarouselWithCards = (props) => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    centerMode: true,
    responsive: [
      {
        breakpoint: 767, // For tablets and smaller devices
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false
        },
      },
      {
        breakpoint: 992, // For iPad
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: false
        },
      },
    ],
  };
  
  const cardItems = props.data;

  return (
    
      <Slider {...settings} className='client-carousel'> 
        {cardItems.map((item) => (
          <div key={item.id}>
            <div className='card-container'>
            <img src={cardbg} className='card-bg' alt="blue background" />
             <Card className='card'> 
              <img src={bluepattern} style={{width:"50px",position:"absolute", zIndex:"99",top:"0", left:"0"}} alt='pattern image' />
              <img src={item.image} alt={item.name} className='carousel-img' />
              <CardContent style={{overflow:"auto"}}> 
                <Typography variant="h6">{item.name}</Typography>
                <Typography variant="body2" >{parse(item.info)}</Typography>
              </CardContent>
            </Card>
            </div>
          </div>
        ))}
      </Slider>
 
  );
};

export default CarouselWithCards;
