// BlogCard.js
import { React, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom'; // Import Link from React Router

const BlogCard = ({blog}) => {
    
  const [blogImage, setBlogImage] = useState(null);

  useEffect(() => {
    const importImage = async () => {
      const imageModule = await import(`../images/${blog.image}`);
      setBlogImage(imageModule.default);
    };

    importImage();
  }, [blog.image]);  

  return (
      <Link to={`/blog/${blog.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
        <Row className='blogcard mx-1 mx-md-0 mb-5'>
            <Col md="8" sm="7">
                <p className='date'>{blog.date}</p>
                <h3>{blog.title}</h3>
                <p style={blog.styles}>{blog.type}</p>
            </Col>
            <Col md="4" sm="5">
                <img src={blogImage} alt={blog.title} />
            </Col>
        </Row>
      </Link>
  );
};

export default BlogCard;
