import React from "react";
import Accordioncomp from '../components/accordion';
import Header from "../components/header";
import Footer from "../components/footer";
import '../styles/faq.css';
import faqItems from '../components/faqs.json'

const faq = ()=>{

  return(
    <div className="sec-start">
        <Header/>
        <div className="container faqpage">
        <h1 className="text-center">Frequently Asked Question</h1>
         <Accordioncomp Items={faqItems} />
        </div>
        <Footer/>
    </div>
  )

}

export default faq;