import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

function DropdownMenu({ title, links, isOpen, onToggle }) {
  const dropdownRef = useRef(null);

  const handleDropdownItemClick = () => {
    onToggle(!isOpen); // Toggle the dropdown
  };

  return (
    <div className="custom-dropdown" ref={dropdownRef}>
      <button className="dropdown-toggle" onClick={() => onToggle(!isOpen)}>
        <span>
        {title} 
        </span>
        {isOpen ? <FaAngleUp /> : <FaAngleDown />}
      </button>
      {isOpen && (
        <ul className="dropdown-menu" style={{ top: dropdownRef.current.offsetTop + dropdownRef.current.offsetHeight, left: dropdownRef.current.offsetLeft }}>
          {links.map((link, index) => (
            <li key={index}>
              <Link to={link.to} onClick={handleDropdownItemClick}>
                {link.text}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default DropdownMenu;
