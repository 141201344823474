import React from 'react';
import Slider from 'react-slick';
import { Button, Card, Typography } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ArrowForward from '@mui/icons-material/ArrowForward';
import { useState } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const CustomNextArrow = ({ onClick }) => {
  const [clicked, setClicked] = useState(false);

  const handleButtonClick = () => {
    setClicked(!clicked);
    onClick();
  };

  return (
    <Button
      className={`custom-arrow custom-next-arrow${clicked ? ' clicked' : ''}`}
      onClick={handleButtonClick}
    >
      <ArrowForward />
    </Button>
  );
};

const CustomPrevArrow = ({ onClick }) => {
  const [clicked, setClicked] = useState(false);

  const handleButtonClick = () => {
    setClicked(!clicked);
    onClick();
  };

  return (
    <Button
      className={`custom-arrow custom-prev-arrow${clicked ? ' clicked' : ''}`}
      onClick={handleButtonClick}
    >
      <ArrowBack />
    </Button>
  );
};

const CarouselWithCards = (props) => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    centerMode: true,
    responsive: [
      {
        breakpoint: 767, // For tablets and smaller devices
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false
        },
      },
      {
        breakpoint: 992, // For iPad
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: false
        },
      },
    ],
  };
  
  const cardItems = props.data;

  return (
    
      <Slider {...settings} className='Team'> 
        {cardItems.map((item) => (
          <div key={item.id} >
             <Card className='blogCarouselcard' > 
             <img src={item.image} alt={item.title} style={{objectFit:"contain"}} className='blogcarousel-img' />
              <div className='container mt-3 mb-3'>
               <div className='details'>
                  <span className='role'>{item.role}</span>
                  <span className='name'>{item.name}</span>
               </div>
              <Typography className='title mt-2'>{item.title}</Typography>
              <Typography >{item.text}</Typography>
              </div>
            </Card>
          </div>
        ))}
      </Slider>
 
  );
};

export default CarouselWithCards;
