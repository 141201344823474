import React from 'react';
import Slider from 'react-slick';
import { Button, Card, Typography } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ArrowForward from '@mui/icons-material/ArrowForward';
import { useState } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom';


const CustomNextArrow = ({ onClick }) => {
  const [clicked, setClicked] = useState(false);

  const handleButtonClick = () => {
    setClicked(!clicked);
    onClick();
  };

  return (
    <Button
      className={`custom-arrow custom-next-arrow${clicked ? ' clicked' : ''}`}
      onClick={handleButtonClick}
    >
      <ArrowForward />
    </Button>
  );
};

const CustomPrevArrow = ({ onClick }) => {
  const [clicked, setClicked] = useState(false);

  const handleButtonClick = () => {
    setClicked(!clicked);
    onClick();
  };

  return (
    <Button
      className={`custom-arrow custom-prev-arrow${clicked ? ' clicked' : ''}`}
      onClick={handleButtonClick}
    >
      <ArrowBack />
    </Button>
  );
};

const CarouselWithCards = (props) => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    centerMode: true,
    responsive: [
      {
        breakpoint: 767, // For tablets and smaller devices
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false
        },
      },
      {
        breakpoint: 992, // For iPad
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: false
        },
      },
    ],
  };
  
  const cardItems = props.data;

  return (
    
      <Slider {...settings}> 
        {cardItems.map((item) => (
          <div key={item.id}>
            <Link to={`/blog/${item.id}`} style={{ textDecoration: 'none', color: 'inherit' }}
              onClick={() => window.scrollTo(0, 0)} // Add onClick event handler
            >
             <Card className='blogCarouselcard' > 
             <img src={item.image} alt={item.title} className='blogcarousel-img' />
              <div className='container mt-3 mb-3'>
              <Typography className='date'>{item.date}</Typography>
              <Typography className='title mt-2'>{item.title}</Typography>
              <Typography style={item.style}>{item.type}</Typography>
              </div>
            </Card>
            </Link>
          </div>
        ))}
      </Slider>
 
  );
};

export default CarouselWithCards;
