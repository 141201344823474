import React from "react";
import { Row, Col } from "react-bootstrap";
import Header from "../components/header";
import Footer from "../components/footer";
import { useState } from "react";
import lkdn from "../images/lkdn.png";
import fb from "../images/fb.png";
import insta from "../images/insta.png";
import twtr from "../images/twtr.png";
import google from "../images/google.png";
import yt from "../images/yt.png";
import email from "../images/Email.svg";
import phone from "../images/phone.svg";
import work from "../images/work.svg";
import loc from "../images/loc.svg";
import axios from "axios";


const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    query: ""
  });

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .post("http://localhost:3001/contactformfillup", formData) // Pass formData directly
      .then((res) => {console.log("registered successfully")
      setFormData({
        name: "",
        email: "",
        mobileNumber: "",
        query: ""
      });
       })
      .catch((err) => {console.log(err)});
  };

  return (
    <div className="sec-start">
      <Header />
      <div className="container mt-5 mb-5">
        <Row className="contact-row">
          <Col md="12" lg="5">
            <h1>Get In Touch With Us!</h1>
            <p>
            At ALZEA INDIA, our commitment extends beyond empowering students worldwide;
            we are dedicated to providing unwavering support and a receptive ear to all. Whether
            you have a problem, query, or question, our team is here for you. Reach out to us, and
            let us assist you on your journey.
            </p>
            <p>We look forward to connecting with you!</p>
            <div>
              <a href="https://www.linkedin.com/company/alzea-india/" rel="noreferrer" target="_blank" ><img src={lkdn} alt="Linkedin" className="sm-icons" /></a>
              <a href="https://www.instagram.com/alzeaindia/?hl=en" rel="noreferrer" target="_blank" ><img src={insta} alt="Insta" className="sm-icons" /></a>
              <a href="https://www.facebook.com/ALZEA.internships/" rel="noreferrer" target="_blank" ><img src={fb} alt="Facebook" className="sm-icons" /></a>
              <a href="https://twitter.com/alzeaindia?lang=en" rel="noreferrer" target="_blank" ><img src={twtr} alt="Twitter" className="sm-icons" /></a>
              <a href="https://www.youtube.com/channel/UCeO6Aa3Kv20zFb0OFgT2zbQ" rel="noreferrer" target="_blank" ><img src={yt} alt="Youtube" className="sm-icons" /></a>
              <a href="https://g.co/kgs/79SgR6y" rel="noreferrer" target="_blank" ><img src={google} alt="Google" className="sm-icons" /></a>
            </div>
          </Col>
          <Col md="12" lg="6">
            <form onSubmit={handleSubmit}>
              <label htmlFor="name">Full Name:</label>
              <input
                type="text"
                placeholder="John Doe"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />

              <label htmlFor="email">Email ID:</label>
              <input
                type="tel"
                placeholder="johndoe@abc.com"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />

              <label htmlFor="mobileNumber">Mobile Number:</label>
              <input
                type="tel"
                placeholder="829-983-1929"
                id="mobileNumber"
                name="mobileNumber"
                value={formData.mobileNumber}
                onChange={handleChange}
              />

              <label htmlFor="query">Enter Your Query:</label>
              <textarea
                rows="4"
                cols="50"
                placeholder="Leave a message"
                id="query"
                name="query"
                value={formData.query}
                onChange={handleChange}
              ></textarea>

              <button type="submit">Contact us</button>
            </form>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col lg="3" md="6" sm="12" className="contact-cards-sec">
            <Row className="contact-cards">
              <Col lg="2">
                <img src={email} alt="Email" />
              </Col>
              <Col lg="8">
                <p>
                  <b>Email</b>
                </p>
                <p><a href="mailto:priyanka@alzeaindia.com" >priyanka@alzeaindia.com</a></p>
              </Col>
            </Row>
          </Col>
          <Col lg="3" md="6" sm="12" className="contact-cards-sec">
          <Row className="contact-cards">
              <Col lg="2">
              <img src={phone} alt="Phone Number" />
              </Col>
              <Col lg="8">
                <p>
                  <b>Phone</b>
                </p>
                <p><a href="tel:+91 9561101102">+91 9561101102</a></p>
              </Col>
            </Row>
          </Col>
          <Col lg="3" md="6" sm="12" className="contact-cards-sec">
          <Row className="contact-cards">
              <Col lg="2">
              <img src={work} alt="Work Time" />
              </Col>
              <Col lg="8">
                <p>
                  <b>Work Time</b>
                </p>
                <p>9:00 AM - 5:00 PM</p>
              </Col>
            </Row>
          </Col>
          <Col lg="3" md="6" sm="12" className="contact-cards-sec">
          <Row className="contact-cards">
              <Col lg="2">
              <img src={loc} alt="Location" />
              </Col>
              <Col lg="8">
                <p>
                  <b>Location</b>
                </p>
                <p>Thane, India</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
