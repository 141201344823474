import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Image } from "react-bootstrap";
import lkdn from "../images/lkdn.png";
import fb from "../images/fb.png";
import insta from "../images/insta.png";
import twtr from "../images/twtr.png";
import google from "../images/google.png";
import yt from "../images/yt.png";
import blogData from "../components/blogData.json";
import CarouselWithCards from "../components/blogcarousel";
import Header from "../components/header";
import Footer from "../components/footer";
import parse from 'html-react-parser';

const IndividualBlogPage = () => {
  const { id } = useParams(); // Extract the 'id' parameter from the URL
  const blogId = parseInt(id);
  const blog = blogData.find((blog) => blog.id === blogId);

  const [blogImage, setBlogImage] = useState(null);
  const [otherBlogsForCarousel, setOtherBlogsForCarousel] = useState([]);

  useEffect(() => {
    const importImage = async () => {
      if (blog) {
        const imageModule = await import(`../images/${blog.image}`);
        setBlogImage(imageModule.default);
      }
    };

    importImage();
  }, [blog]);

  useEffect(() => {
    const otherBlogs = blogData.filter((b) => b.id !== blogId);
    const importImages = async () => {
      const images = await Promise.all(
        otherBlogs.map(async (otherBlog) => {
          const imageModule = await import(`../images/${otherBlog.image}`);
          return imageModule.default;
        })
      );
      setOtherBlogsForCarousel(
        otherBlogs.map((otherBlog, index) => ({
          id: otherBlog.id,
          title: otherBlog.title,
          image: images[index],
          date: otherBlog.date,
          type: otherBlog.type,
          style: otherBlog.styles
        }))
      );
    };

    importImages();
  }, [blogId]);

  if (!blog) {
    return <div>Blog not found!</div>;
  }

  const blogcontent = parse(blog.content);

  return (
    <div className="sec-start">
      <Header />
      <Container className="mt-5 mb-5">
        <Row>
          <Col xs={12} md={1}>
            <a href="https://www.linkedin.com/company/alzea-india/" rel="noreferrer" target="_blank">
              <img
                src={lkdn}
                alt="Linkedin"
                className="sm-icons sm-icons-blog"
              />
            </a>
            <a href="https://www.instagram.com/alzeaindia/?hl=en" rel="noreferrer" target="_blank">
              <img src={insta} alt="Insta" className="sm-icons sm-icons-blog" />
            </a>
            <a href="https://www.facebook.com/ALZEA.internships/" rel="noreferrer" target="_blank">
              <img src={fb} alt="Facebook" className="sm-icons sm-icons-blog" />
            </a>
            <a href="https://twitter.com/alzeaindia?lang=en" rel="noreferrer" target="_blank">
              <img src={twtr} alt="Twitter" className="sm-icons sm-icons-blog" />
            </a>
            <a href="https://www.youtube.com/channel/UCeO6Aa3Kv20zFb0OFgT2zbQ" rel="noreferrer" target="_blank">
              <img src={yt} alt="Youtube" className="sm-icons sm-icons-blog" />
            </a>
            <a href="https://g.co/kgs/79SgR6y" rel="noreferrer" target="_blank">
              <img src={google} alt="Google" className="sm-icons sm-icons-blog" />
            </a>
          </Col>

          <Col xs={12} md={11}>
            <div>
              <small>{blog.date}</small>
              <h2>{blog.title}</h2>
              <p style={blog.styles} className="my-3">{blog.type}</p>
              <Image src={blogImage} className="mb-4" style={{width:"100%", objectFit:"cover", maxHeight:"500px"}} fluid />
              <p>{blogcontent}</p>
            </div>
          </Col>
        </Row>
        <h3 className="mt-5">Check out These Related Blogs</h3>
        <CarouselWithCards data={otherBlogsForCarousel} />
      </Container>
      <Footer />
    </div>
  );
};

export default IndividualBlogPage;
