import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import Header from "../components/header";
import Footer from "../components/footer";
import { Link } from "react-router-dom";
import banner from "../images/why-alzea-banner.webp";
import workPermit from "../images/work-permit.webp";
import whylearn from "../images/why-learn.webp";
import whyStudyThroughUs from "../images/why-study-through-us.webp";
import whyUsForStudyTrips from "../images/why-us-for-study-trips.webp";
import star from "../images/star.svg";
import CardsAccordion from "../components/cardsAccordion";
import Eiffel from '../images/eiffel.png';
import SingleCarousel from "../components/singlecarousel";
import studentsData from "../components/studentData.json";

function WhyAlzea() {
  const whyLearn = [
    {
      no: "1",
      heading: "Experienced Certified Instructors:",
      content:
        "Our instructors bring a wealth of experience and firsthand knowledge of French culture, having lived and studied in France. With their expertise, you'll gain insights beyond textbooks, ensuring a deeper understanding and appreciation of the language.",
    },
    {
      no: "2",
      heading: "Cultural and Industry-Specific Inclusions:",
      content:
        "Our instructors bring a wealth of experience and firsthand knowledge of French culture, having lived and studied in France. With their expertise, you'll gain insights beyond textbooks, ensuring a deeper understanding and appreciation of the language.",
    },
    {
      no: "3",
      heading: "Practical Focus on Conversational Proficiency:",
      content:
        "We prioritize teaching French as a foreign language, emphasizing practical conversational skills over rote memorization. Our dynamic classes focus on real-world applications, empowering you to communicate effectively and fluently in French-speaking environments.",
    }
  ];
  const cards = [
     {
      title:"Overcoming Cultural Shock",
      para:"We understand the challenges of adapting to a new culture, which is why we offer complimentary French language services. Through our dedicated portal, Fluentzea - Fluent with ALZEA, we provide comprehensive training in French tailored specifically for our intern students. Equipped with hotel management vocabulary, our students can pursue their internships with confidence, enhance their networking opportunities, and overcome language barriers seamlessly."
     },
      {
      title:"Combatting Homesickness and Fatigue",
      para:"At ALZEA INDIA, we prioritise building relationships and fostering a supportive community. Recognizing the common experiences of homesickness and exhaustion, we offer counselling and consultancy sessions, along with guidance from certified health coaches. Whether you need help managing stress or building a healthy lifestyle while working internationally, we're here to support you every step of the way."
      },
      {
      title:"Simplifying the Process",
      para:"Navigating the internship process can be daunting, but with our years of experience and expertise, we've perfected every step. From document storage to providing timely assistance, from understanding government requirements to facilitating visa applications, we ensure a smooth and organised process. Rest assured, at ALZEA INDIA, we've got you covered from start to finish, eliminating any confusion or uncertainty along the way."
    }
  ];
  const cards2 = [
    {
     title:"Streamlined Process:",
     para:"From application to visa, we guide you every step of the way. Our comprehensive support ensures a smooth journey, including <b>mock interviews</b> to help you excel in the admissions process."
    },
     {
     title:"Diverse Program Offerings:",
     para:"Explore a wide range of programs across French-speaking countries. Whether in hotel management, marketing, or fashion design, we offer diverse opportunities tailored to your interests and aspirations."
     },
     {
     title:"French Language Training:",
     para:"Access free French language training to enhance your networking abilities and overcome cultural shock. Our language sessions prepare you for seamless integration into your chosen Francophone destination, leading to a fulfilling academic and cultural experience."
   }
 ];

  return (
    <div className="sec-start">
      <Header />
     <Container>
        <Row className="mt-2 mb-2">
          <Col md="6" sm="12">
            <h1>Why Alzea India?</h1>
            <p>
            Hub of everything francophone, ALZEA INDIA stands as a pioneer in hospitality internships in France, having initiated our services early in the Indian sector, and holds a trustworthy position with internships in Mauritius, study programs and trips across the francophonie. What sets us apart is our pioneering approach of including complimentary French language services in all our programs, eliminating any cultural shock. Our extensive experience speaks volumes, but what truly sets us apart is our family-like approach. We don't just do business; we cultivate relationships built on trust, empathy, and genuine care. From the moment a student joins our program until long after they've completed their course, we provide unwavering, end-to-end support. Join us on a journey where excellence meets compassion, and together, let's shape futures and create lasting memories.
            </p>
            <Link to="/contact" className="button-contact py-3">
              Contact us
            </Link>
          </Col>
          <Col md="6" sm="12">
            <img
              src={banner}
              alt="Hero Banner Why Alzea page"
              className="section-img"
            />
          </Col>
        </Row>
        </Container>
        <Container>
        <Row className="mt-5 mb-5">
          <h2 className="text-center mt-2 mb-4">
            Why Do Internships/Work Permits With Us?
          </h2>
          <Col md="6" sm="12">
            <img src={workPermit} alt="Work Permit" className="section-img" />
          </Col>
          <Col md="6" sm="12">
            <CardsAccordion data={cards} />
          </Col>
        </Row>
        </Container>
        <Container>
        <Row className="pt-5 pb-5">
          <Col md="6" sm="12">
            <h2>Why Learn French With Us?</h2>
            {whyLearn.map((item, index) => {
              return (
                <div style={{display:"flex",justifyContent:"space-between", gap:"10px",alignItems:"center"}}>
                    <span className="number">{item.no}</span>
                    <span>
                      <b>{item.heading}</b>
                      <p>{item.content}</p>
                    </span>
                </div>
              );
            })}
          </Col>
          <Col md="6" sm="12">
            <img
              src={whylearn}
              alt="Why Learn French With Us"
              className="section-img"
            />
          </Col>
        </Row>
        </Container>
        <section style={{backgroundColor:"#F5F4F4"}}>
        <Container>
        <Row className="pt-5 pb-5" style={{backgroundColor:"#F5F4F4;"}}>
          <h2 className="text-center mt-2 mb-4">
            Why go For Study programs Through Us?
          </h2>
          <Col md="6" sm="12">
            <img
              src={whyStudyThroughUs}
              alt="Work Permit"
              className="section-img"
            />
          </Col>
          <Col md="6" sm="12">
          <CardsAccordion data={cards2} />
          </Col>
        </Row>
        </Container>
        </section>
        <Container>
        <Row className="pt-5 pb-5 d-flex justify-content-center">
          <h2 className="text-center mt-2 mb-4">
            Why Select Us For Your Study Trips?
          </h2>
          <p>
            Opting for study trips with ALZEA INDIA offers an enriching and
            transformative educational experience that goes beyond traditional
            learning. ALZEA INDIA's study trips are designed to expose
            participants to diverse cultural environments, fostering cultural
            intelligence and adaptability.Study trips often include networking
            events, workshops, and interactions that can open doors to future
            opportunities. Acquire skills, insights, and knowledge that are
            valuable in your career and competitiveness in the job market.
            Beyond academic or professional benefits, study trips contribute to
            personal growth. Participants often return with increased
            confidence, independence, and a broader worldview.
          </p>
          <img src={whyUsForStudyTrips} className="mb-3" alt="Why Us For Study Trips" />
          <div className="star-content">
            <img src={star} alt="star"/>
            <span>
              Lorem ipsum dolor sit amet consectetur. Id nunc nibh eget
              adipiscing lectus quam habitasse facilisis. am habitasse
              facilisis.
            </span>
          </div>
          <div className="star-content">
            <img src={star} alt="star"/>
            <span>
              Lorem ipsum dolor sit amet consectetur. Id nunc nibh eget
              adipiscing lectus quam habitasse facilisis. am habitasse
              facilisis.
            </span>
          </div>
          <div className="star-content">
            <img src={star} alt="star"/>
            <span>
              Lorem ipsum dolor sit amet consectetur. Id nunc nibh eget
              adipiscing lectus quam habitasse facilisis. am habitasse
              facilisis.
            </span>
          </div>
          <div className="star-content">
            <img src={star} alt="star"/>
            <span>
              Lorem ipsum dolor sit amet consectetur. Id nunc nibh eget
              adipiscing lectus quam habitasse facilisis. am habitasse
              facilisis.
            </span>
          </div>
        </Row>
        </Container>
        <Row className="whyAlzeaCarousel pt-5 pb-5">
          <Container>
            <h2 className="text-center">Highlighted positive experiences of our students</h2>
            <SingleCarousel data={studentsData} />
          </Container>
        </Row>
        <Container>
        <Row className="mt-5 mb-5" style={{background:"#1A2B60", borderRadius:"20px", margin:"0 auto"}}>
          <Col md="7" className="eiffel-content">
             <h2>DO YOU WISH TO KNOW MORE ABOUT US?</h2>
            <p>CONTACT US NOW</p>
            <Link to="/contact" className="eiffel-button-contact py-3">
              Contact us
            </Link>
          </Col>
          <Col md="5">
            <img src={Eiffel} style={{width:"100%"}} alt="Eiffel Tower"/>
          </Col>
        </Row>
        </Container>
      <Footer />
    </div>
  );
}

export default WhyAlzea;
