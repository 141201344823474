import React, { useRef, useState } from "react";
import { Link } from "react-router-dom"; // Import Link from React Router DOM
import "../styles/Header.css";
import logo from "../images/logo.png";
import { FaBars, FaTimes } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";
import DropdownMenu from "./dropdown";

function Header() {
  const navRef = useRef();
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);

  const toggleNavbar = () => {
    setIsNavOpen(!isNavOpen);
    navRef.current.classList.toggle("responsive_nav");
    if (!isNavOpen) {
      navRef.current.classList.add("nav-btn-hidden");
    } else {
      navRef.current.classList.remove("nav-btn-hidden");
    }
  };
  const handleDropdownToggle = (index, isOpen) => {
    setOpenDropdownIndex(isOpen ? index : null); // Close dropdown if it's already open
  };
  const internshipsLinks = [
    { to: "/internships-in-france", text: "Internships in france" },
    { to: "/internships-in-mauritius", text: "Internships in mauritius" },
  ];

  const studyTripsLinks = [
    { to: "/study-trips-in-france", text: "Study Trips In France" },
    { to: "/", text: "Trip 2" },
  ];

  return (
    <header>
      <div className="container">
        <img src={logo} alt="logo" />
        <div className="menu">
          <nav ref={navRef}>
            {isNavOpen ? (
              <img src={logo} className="logo-collapsed" alt="logo" />
            ) : (
              ""
            )}
            <Link to="/">Home</Link>
            <Link to="/about">About</Link>
            <Link to="/blogs">Blogs</Link>
            <DropdownMenu
              title="Internships"
              links={internshipsLinks}
              isOpen={openDropdownIndex === 0}
              onToggle={(isOpen) => handleDropdownToggle(0, isOpen)}
            />
            <DropdownMenu
              title="Study Trips"
              links={studyTripsLinks}
              isOpen={openDropdownIndex === 1}
              onToggle={(isOpen) => handleDropdownToggle(1, isOpen)}
            />
            <Link to="/study-programs">Study Programs</Link>
            {/* Replace <a> tag with Link component */}
            <Link to="/fluentzea">Fluentzea</Link>
            {/* Replace <a> tag with Link component */}
            {isNavOpen && (
              <button className="nav-btn nav-close-btn" onClick={toggleNavbar}>
                <FaTimes />
              </button>
            )}
            <Link to="/contact" className="button-contact">
              Contact us
            </Link>
          </nav>
          <button
            className={isNavOpen ? "none" : "nav-btn nav-btn-hidden"}
            onClick={toggleNavbar}
          >
            <FaBars />
          </button>
        </div>
      </div>
    </header>
  );
}

export default Header;
